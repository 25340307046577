import React, { Fragment, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import { view } from "@risingstack/react-easy-state";

import { news } from "../../stores/BaseStore";
import NewsFeaturedBox from "../news/NewsFeaturedBox";
import NewsBox from "../news/NewsBox";
import HeroEvents from "../shared/HeroEvents";
import Loader from "../shared/Loader";
import Footer from "../shared/Footer";

const StyledContainer = styled(Container)`
  position: relative;
  z-index: 2;
  padding-top: 230px;
`;

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const News = view(() => {
  useEffect(() => {
    if (!news.news) {
      news.getNews();
    }
  }, []);

  return (
    <Fragment>
      <StyledContainer fixed>
        {!news.news ? (
          <Fragment>
            <StyledSpacer />
            <Loader />
          </Fragment>
        ) : (
          <Fragment>
            <Grid container spacing={4} style={{ marginBottom: "88px" }}>
              {news.news.map((singleNews, index) => {
                if (index === 0) {
                  return (
                    <Grid
                      item
                      xs={12}
                      style={{ marginBottom: "28px" }}
                      key={singleNews.id}
                    >
                      <NewsFeaturedBox singleNews={singleNews} />
                    </Grid>
                  );
                } else {
                  return (
                    <Grid item xs={12} md={4} key={singleNews.id}>
                      <NewsBox singleNews={singleNews} />
                    </Grid>
                  );
                }
              })}
            </Grid>
            <HeroEvents />
          </Fragment>
        )}
      </StyledContainer>
      <Footer />
    </Fragment>
  );
});

export default News;
