import React, { useEffect } from "react";

import { BrowserRouter as Router } from "react-router-dom";
import { view } from "@risingstack/react-easy-state";
import { Hub } from "@aws-amplify/core";
import "@aws-amplify/ui/dist/style.css";

import { auth } from "./stores/BaseStore";

import ScrollToTop from "./components/shared/ScrollToTop";
import Wrapper from "./components/Wrapper";

const App = view(() => {
  useEffect(() => {
    Hub.listen("auth", auth.getUser); // listen for login/signup events
    auth.getUser(); // check manually the first time because we won't get a Hub event
    return () => Hub.remove("auth", auth.getUser); // cleanup
  }, []);

  return (
    <Router>
      <ScrollToTop />
        <Wrapper />
    </Router>
  );
});

export default App;
