import React, { Fragment, useEffect } from "react";
import { view } from "@risingstack/react-easy-state";

import { auth } from "../stores/BaseStore";

const AuthStateHandler = view((props) => {
  useEffect(() => {
    if (auth.authState !== props.authState) {
      auth.authState = props.authState;
    }
  }, [props.authState]);

  return <Fragment></Fragment>;
});

export default AuthStateHandler;
