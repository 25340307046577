import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";

const StyledFooterWrapper = styled(Grid)`
  position: fixed;
  z-index: 3;
  bottom: 0;
  left: 0;
  padding: 0 0 56px 0;

  @media (max-width: 959px) {
    position: relative;
    background-color: #161e20;
  }
`;

const StyledFooterInner = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 959px) {
    justify-content: center;
  }
`;

const StyledLink = styled(Link)`
  all: unset;
  cursor: pointer;
  font-family: "AzoSans Regular", "Arial", sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.117647px;
  margin-right: 40px;

  &:hover {
    color: #0091d4;
  }

  &.active {
    color: #0091d4;
  }

  @media (max-width: 959px) {
    margin-right: 20px;
  }
`;

const GdprFooter = () => {
  const location = useLocation();
  return (
    <StyledFooterWrapper container>
      <Container fixed>
        <Grid item xs={12}>
          <StyledFooterInner>
            <StyledLink
              to="/terms-and-conditions"
              className={
                location.pathname === "/terms-and-conditions" ? "active" : null
              }
            >
              Terms and conditions
            </StyledLink>
            <StyledLink
              to="/privacy"
              className={location.pathname === "/privacy" ? "active" : null}
            >
              Privacy policy
            </StyledLink>
            <StyledLink
              to="/impressum"
              className={location.pathname === "/impressum" ? "active" : null}
            >
              Impressum
            </StyledLink>
          </StyledFooterInner>
        </Grid>
      </Container>
    </StyledFooterWrapper>
  );
};

export default GdprFooter;
