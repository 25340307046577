import React, { Fragment } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { view } from "@risingstack/react-easy-state";

import { auth, events } from "../../stores/BaseStore";
import PlayerStatsDota from "./PlayerStatsDota";
import EventBox from "../events/EventBox";
import Loader from "../shared/Loader";

const StyledTitleWrapper = styled(Box)`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const StyledBackButton = styled(Link)`
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 16px;
  line-height: 12px;
  letter-spacing: 1.42857px;
  color: #ffffff;
  margin-bottom: 42px;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  svg {
    margin-right: 26px;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const StyledNumberBox = styled(Box)`
  background: #0091d4;
  margin-right: 24px;
  height: 88px;
  width: 88px;
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 36px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 3.21429px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 959px) {
    margin-bottom: 24px;
  }
`;

const StyledNameBox = styled(Box)`
  @media (max-width: 959px) {
    width: 100%;
    margin-bottom: 24px;
  }
`;

const StyledSkill = styled.p`
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.0857143px;
  color: #a7acb3;
  margin: 0;
  margin-bottom: 3px;
`;

const StyledName = styled.p`
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 36px;
  line-height: 48px;
  letter-spacing: 0.264706px;
  color: #ffffff;
  margin: 0;
  margin-bottom: 3px;
`;

const StyledRank = styled.p`
  font-size: 21px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #0091d4;
  margin: 0;

  span {
    font-family: "AzoSans Black", "Arial", sans-serif;
    color: #ffffff;
  }
`;

const StyledButtonWrapper = styled(Box)`
  @media (max-width: 959px) {
    width: 100%;
    max-width: 300px;
  }
`;

const StyledButtonSecondary = styled.button`
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid #fafafa;
  border-radius: 0;
  padding: 20px;
  cursor: pointer;
  letter-spacing: 1.42857px;

  &:hover {
    opacity: 0.8;
  }
`;

const StyledImageWrapper = styled(Box)`
  // margin-right: 30px;
  height: 336px;
  width: 336px;

  img {
    margin-bottom: 12px;
    height: 100%;
    width: 100%:
    object-fit: cover;
  }
`;

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const PlayerDetailsDota = view((props) => {
  const { playerDetails } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Fragment>
      {!auth.user || !events.events ? (
        <Fragment>
          <StyledSpacer />
          <Loader />
        </Fragment>
      ) : (
        <Fragment>
          <Grid container>
            <Grid item xs={12}>
              <StyledBackButton to="/players">
                <ArrowBackIcon fontSize="small" />
                <span>back to players</span>
              </StyledBackButton>
            </Grid>
          </Grid>
          <Grid
            container
            justify={"space-between"}
            alignItems={"center"}
            style={{ marginBottom: "55px" }}
          >
            <StyledTitleWrapper>
              <StyledNumberBox>{playerDetails.id}</StyledNumberBox>
              <StyledNameBox>
                <StyledSkill>Veteran</StyledSkill>
                <StyledName>{playerDetails.dota2_name}</StyledName>
                <StyledRank>
                  Rank{" "}
                  <span>
                    {playerDetails.rank_tier
                      ? playerDetails.rank_tier
                      : "NO DATA"}
                  </span>
                </StyledRank>
              </StyledNameBox>
            </StyledTitleWrapper>
            <StyledButtonWrapper>
              <StyledButtonSecondary>Update stats</StyledButtonSecondary>
            </StyledButtonWrapper>
          </Grid>
          <Grid container spacing={3} style={{ marginBottom: "102px" }}>
            <Grid item xs={12} lg={1}></Grid>
            <Grid item xs={12} lg={4}>
              <StyledImageWrapper>
                <img src={playerDetails.avatar_link} alt="player_avatar" />
              </StyledImageWrapper>
            </Grid>
            <Grid item xs={12} lg>
              <PlayerStatsDota playerDetails={playerDetails} />
            </Grid>
          </Grid>
          <Grid
            container
            justify={"space-between"}
            alignItems={"center"}
            style={{ marginBottom: "32px" }}
          >
            <Box>
              <h4 style={{ margin: 0 }}>events</h4>
            </Box>
          </Grid>
          <Grid
            container
            spacing={matches ? 9 : 4}
            style={{ marginBottom: "38px" }}
          >
            {events.events.map((singleEvent, index) => {
              if (index < 2) {
                return (
                  <Grid item xs={12} md={6} key={singleEvent.id}>
                    <EventBox singleEvent={singleEvent} />
                  </Grid>
                );
              }
              return null;
            })}
          </Grid>
        </Fragment>
      )}
    </Fragment>
  );
});

export default PlayerDetailsDota;
