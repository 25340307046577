import React, { useRef } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import styled from "styled-components";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { view, autoEffect } from "@risingstack/react-easy-state";

import { contact } from "../stores/BaseStore";
import Loader from "./shared/Loader";

const StyledDialog = styled(Dialog)`
  .MuiBackdrop-root {
    background: #161e20;
    mix-blend-mode: normal;
    opacity: 0.5 !important;
  }

  .MuiPaper-root {
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
    overflow: visible;
  }
`;

const StyledOuterWrapper = styled(Box)`
  position: relative;
  background: #273032;
  width: 100%;
  z-index: 3;

  h3 {
    margin: 0;
    line-height: 80px;

    span {
      color: #0091d4;
    }
  }

  @media (max-width: 1279px) {
    overflow-y: scroll;
  }
`;

const StyledInnerWrapper = styled(Box)`
  position: relative;
  z-index: 3;
  margin-bottom: 2rem;
`;

const StyledField = styled(Field)`
  width: 100%;
  margin-bottom: 3rem;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s;
    -webkit-text-fill-color: #a7acb3 !important;
  }

  input:-webkit-autofill::first-line {
    font-size: 16px;
  }

  .MuiInput-input {
    font-family: "AzoSans Regular", "Arial", sans-serif;
    font-size: 16;
    line-height: 24px;
    letter-spacing: 0.15px;
    padding: 20px 16px 20px 16px;
  }

  .MuiInputLabel-root {
    font-family: "AzoSans Bold", "Arial", sans-serif;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.25px;
    padding-left: 20px;
  }

  .MuiFormHelperText-root {
    position: absolute;
    bottom: -30px;
    left: 16px;
  }

  .MuiInput-underline::before {
    border-bottom: 1px solid #ffffff;
  }

  .MuiInput-underline:hover:not(.Mui-disabled)::before {
    border-bottom: 1px solid #ffffff;
  }

  .MuiInput-underline::after {
    border-bottom: 1px solid #0091d4;
  }

  .MuiFormLabel-root {
    font-family: "Polaris", "Arial", sans-serif;
    color: #ffffff;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #0091d4;
  }
`;

const StyledButtonPrimary = styled.button`
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  background-color: #0091d4;
  border: 1px solid #0091d4;
  border-radius: 0;
  padding: 20px;
  cursor: pointer;
  letter-spacing: 1.42857px;
  margin-top: 2rem;

  &:hover {
    opacity: 0.8;
  }

  width: 100%;
`;

const StyledSideWrapper = styled(Box)`
  position: absolute;
  top: 100px;
  left: -136px;
  background-color: #161e20;

  @media (max-width: 1279px) {
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 72px;
  }
`;

const StyledSideWrapperInner = styled(Box)`
  padding: 32px 59px 32px 32px;
`;

const StyledSideWrapperTitle = styled.p`
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: 0.176471px;
  color: #0091d4;
  margin: 0;
  margin-bottom: 5px;
`;

const StyledSideWrapperSubtitle = styled.p`
  font-family: "AzoSans Bold", "Arial", sans-serif;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4375px;
  text-transform: uppercase;
  color: #a7acb3;
  margin: 0;
`;

const StyledCloseButton = styled.span`
  // position: fixed;
  // top: 60px;
  // right: 60px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 4;

  svg {
    font-size: 88px;
    color: #0091d4;

    &:hover {
      opacity: 0.8;
    }
  }

  // @media (max-width: 1279px) {
  //   position: relative;
  //   left: 20px;
  //   top: 100px;
  // }
`;

const Contact = view((props) => {
  const { contactDialogue, setContactDialogue } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const formRef = useRef();

  const handleClose = () => {
    setContactDialogue(false);
  };

  autoEffect(() => {
    if (!contact.loading && !contact.errors) {
      setContactDialogue(false);
    }
  });

  // autoEffect(() => {
  //   if (auth.errors) {
  //     if (formRef.current) {
  //       formRef.current.validateForm();
  //     }
  //   }
  // });

  const customValidation = (values) => {
    let errors = {};

    if (!values.email) {
      errors.email = "Email is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    if (!values.full_name) {
      errors.full_name = "Full name is required";
    }

    if (!values.subject) {
      errors.subject = "Subject is required";
    }

    if (!values.message) {
      errors.message = "Message is required";
    }

    return errors;
  };

  return (
    <StyledDialog
      fullWidth={true}
      maxWidth={"md"}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={contactDialogue}
      disableScrollLock={true}
    >
      <StyledOuterWrapper px={matches ? 12 : 6} py={12}>
        <StyledCloseButton onClick={handleClose}>
          <CloseIcon />
        </StyledCloseButton>
        <StyledSideWrapper>
          <StyledSideWrapperInner>
            <StyledSideWrapperTitle>Address</StyledSideWrapperTitle>
            <StyledSideWrapperSubtitle>
              Home United Spaces GmbH i. G.
            </StyledSideWrapperSubtitle>
            <StyledSideWrapperSubtitle>
              Hamburger Ding
            </StyledSideWrapperSubtitle>
            <StyledSideWrapperSubtitle>
              Nobistor 16, 22767 Hamburg
            </StyledSideWrapperSubtitle>
          </StyledSideWrapperInner>
          <StyledSideWrapperInner>
            <StyledSideWrapperTitle>email</StyledSideWrapperTitle>
            <StyledSideWrapperSubtitle>
              gaming@unitedcyberspaces.com
            </StyledSideWrapperSubtitle>
          </StyledSideWrapperInner>
          <StyledSideWrapperInner>
            <StyledSideWrapperTitle>phone</StyledSideWrapperTitle>
            <StyledSideWrapperSubtitle>040/419297000</StyledSideWrapperSubtitle>
          </StyledSideWrapperInner>
        </StyledSideWrapper>
        <Grid container justify="center">
          <Grid item xs={12} md={6} mx="auto">
            <StyledInnerWrapper>
              <h3>
                <span>Contact</span> Us
              </h3>
            </StyledInnerWrapper>
            <StyledInnerWrapper>
              <Formik
                initialValues={{
                  email: "",
                  full_name: "",
                  subject: "",
                  message: "",
                }}
                validate={customValidation}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    setSubmitting(false);
                    contact.sendMessage(values);
                  }, 500);
                }}
                innerRef={formRef}
              >
                {({ submitForm, isSubmitting }) => (
                  <Form>
                    <StyledField
                      component={TextField}
                      name="email"
                      label="email"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      autoComplete="off"
                      type="email"
                    />
                    <StyledField
                      component={TextField}
                      name="full_name"
                      label="full name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      autoComplete="off"
                    />
                    <br />
                    <StyledField
                      component={TextField}
                      name="subject"
                      label="subject"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      autoComplete="off"
                    />
                    <StyledField
                      component={TextField}
                      name="message"
                      label="message"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      autoComplete="off"
                      multiline
                      rows={3}
                    />

                    {isSubmitting && <Loader />}
                    <br />
                    <StyledButtonPrimary
                      type="button"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      Send message
                    </StyledButtonPrimary>
                  </Form>
                )}
              </Formik>
            </StyledInnerWrapper>
          </Grid>
        </Grid>
      </StyledOuterWrapper>
    </StyledDialog>
  );
});

export default Contact;
