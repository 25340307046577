import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { view, autoEffect } from "@risingstack/react-easy-state";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory, useLocation } from "react-router-dom";
import { location } from "../../stores/BaseStore";

const ProfileRoute = view(({ children, auth, gdpr, ...rest }) => {
  let history = useHistory();
  let loc = useLocation();

  autoEffect(() => {
    location.prevLocation = loc.pathname;

    if (!auth.isLoading && !auth.isLoggedIn) {
      history.push("/login");
    }

    if (!auth.isLoading && auth.isLoggedIn && gdpr.terms && !gdpr.loading) {
      redirect();
    }

    // if () {
    //   redirect();
    // }
  });

  const redirect = () => {
    // if (loc.pathname !== "/accept-terms") {
    //   if (gdpr.terms) {
    //     const legalCheck = gdpr.terms.filter((term) => {
    //       return term.required && !term.accepted;
    //     });

    //     if (legalCheck.length !== 0) {
    //       history.push("/accept-terms");
    //     }
    //   }
    // } else {
    //   if (gdpr.terms) {
    //     const legalCheck = gdpr.terms.filter((term) => {
    //       return term.required && !term.accepted;
    //     });

    //     if (legalCheck.length === 0) {
    //       history.push("/");
    //     }
    //   }
    // }

    if (gdpr.terms) {
      const legalCheck = gdpr.terms.filter((term) => {
        return term.required && !term.accepted;
      });

      console.log(location.prevLocation)

      if (legalCheck.length === 0) {
        if (history.location.pathname === "/accept-terms") {
          history.push(location.prevLocation);
        } else return null;
      } else {
        if (history.location.pathname !== "/accept-terms") {
          history.push("/accept-terms");
        } else {
          return null;
        }
      }

      // if (legalCheck.length === 0 && loc.pathname === "/accept-terms") {
      //   console.log("no gdpr");
      //   history.push(location.prevLocation);
      // } else if (legalCheck.length !== 0 && loc.pathname !== "/accept-terms") {
      //   console.log("gdpr");
      //   history.push("/accept-terms");
      // }
    }
  };

  useEffect(() => {
    if (!auth.isLoading && auth.isLoggedIn && gdpr.terms && !gdpr.loading) {
      redirect();
    }

    // eslint-disable-next-line
  }, [history.location.pathname]);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return !auth ? <CircularProgress /> : children;
      }}
    />
  );
});

export default ProfileRoute;
