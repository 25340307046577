import React, { Fragment, useEffect, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { view } from "@risingstack/react-easy-state";

import { news, events } from "../../stores/BaseStore";
import Loader from "../shared/Loader";
import EventBox from "../events/EventBox";
import HeroEventsVertical from "../shared/HeroEventsVertical";
import NewsBox from "../news/NewsBox";
import Footer from "../shared/Footer";
import SearchLol from "../shared/SearchLol";
import SearchDota from "../shared/SearchDota";

const StyledContainer = styled(Container)`
  position: relative;
  z-index: 2;
  padding-top: 430px;
`;

const StyledTitle = styled.h3`
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 36px;
  line-height: 46px;
  letter-spacing: 0.264706px;
  margin: 0;
  margin-bottom: 111px;

  span {
    color: #04b0ff;
  }
`;

const StyledSubtitle = styled.h5`
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 21px;
  line-height: 27px;
  letter-spacing: 0.154412px;
  margin: 0;

  span {
    color: #04b0ff;
  }
`;

const StyledTitleWrapper = styled(Box)`
  p {
    font-size: 21px;
    line-height: 32px;
    letter-spacing: 0.154412px;
    margin-bottom: 23px;
  }

  h4 {
    text-transform: unset;
  }
`;

const StyledInfoWrapper = styled(Box)`
  height: 100%;
`;

const StyledPlayersBox = styled(Box)`
  width: 224px;
  padding: 11px;
  background-color: #0091d4;
`;

const StyledEventsBox = styled(Box)`
  width: 224px;
  padding: 11px;
  background-color: #273032;
`;

const StyledGamesBox = styled(Box)`
  width: 224px;
  padding: 11px;
  background-color: rgba(0, 0, 0, 0.7);
`;

const StyledInfoBoxNumber = styled.p`
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.264706px;
  margin: 0;
`;

const StyledInfoBoxDesc = styled.p`
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.0882353px;
  margin: 0;
`;

const StyledButtonPrimary = styled.button`
  width: 100%;
  color: #ffffff;
  background-color: #0091d4;
  border: 1px solid #0091d4;
  border-radius: 0;
  padding: 30px;
  cursor: pointer;
  font-size: 24px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 2.14286px;

  &:hover {
    opacity: 0.8;
  }

  width: 571px;

  @media (max-width: 959px) {
    width: 100%;
    line-height: normal;
  }
`;

const StyledButtonSmall = styled.button`
  width: 100%;
  color: #ffffff;
  background-color: #0091d4;
  border: 1px solid #0091d4;
  border-radius: 0;
  padding: 20px;
  cursor: pointer;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 2.14286px;

  &:hover {
    opacity: 0.8;
  }

  width: 400px;

  @media (max-width: 959px) {
    width: 100%;
    line-height: normal;
  }
`;

const StyledButtonSecondary = styled.button`
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid #fafafa;
  border-radius: 0;
  padding: 20px;
  cursor: pointer;
  letter-spacing: 1.42857px;
  outline: 0;
  margin-bottom: 23px;

  &:hover {
    opacity: 0.8;
  }

  width: 150px;

  &.active {
    background-color: #0091d4;
    border: 1px solid #0091d4;
  }
`;

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const Home = view(() => {
  const [game, setGame] = useState("lol");
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    if (!news.news) {
      news.getNews();
    }
    if (!events.events) {
      events.getEvents();
    }
  }, []);

  return (
    <Fragment>
      {!news.news || !events.events ? (
        <Fragment>
          <StyledSpacer />
          <Loader />
        </Fragment>
      ) : (
        <StyledContainer fixed>
          <Grid
            container
            justify={"space-between"}
            alignItems={"center"}
            style={{ marginBottom: "102px" }}
          >
            <StyledTitleWrapper>
              <h4 style={{ margin: 0 }}>Player Database</h4>
              <p>Explore players by Name.</p>
              <Box>
                <StyledButtonSecondary
                  style={{ marginRight: "36px" }}
                  onClick={() => {
                    setGame("lol");
                  }}
                  className={game === "lol" ? "active" : null}
                >
                  lol
                </StyledButtonSecondary>
                <StyledButtonSecondary
                  onClick={() => {
                    setGame("dota");
                  }}
                  className={game === "dota" ? "active" : null}
                >
                  dota
                </StyledButtonSecondary>
              </Box>
              {game === "lol" ? <SearchLol /> : <SearchDota />}
            </StyledTitleWrapper>
            <StyledInfoWrapper>
              <StyledPlayersBox>
                <StyledInfoBoxNumber>+5.000</StyledInfoBoxNumber>
                <StyledInfoBoxDesc>Players</StyledInfoBoxDesc>
              </StyledPlayersBox>
              <StyledEventsBox>
                <StyledInfoBoxNumber>+100</StyledInfoBoxNumber>
                <StyledInfoBoxDesc>Events</StyledInfoBoxDesc>
              </StyledEventsBox>
              <StyledGamesBox>
                <StyledInfoBoxNumber>2</StyledInfoBoxNumber>
                <StyledInfoBoxDesc>Games</StyledInfoBoxDesc>
              </StyledGamesBox>
            </StyledInfoWrapper>
          </Grid>
          <Grid
            container
            justify={"flex-end"}
            alignItems={"center"}
            style={{ marginBottom: "163px" }}
          >
            <Grid item xs={12} md={7}>
              <Box>
                <StyledTitle>
                  <span>Esports</span> für jedermann im Herzen Hamburgs
                </StyledTitle>
                <a
                  href="https://discord.gg/y6uG2Ge"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <StyledButtonSmall>Join our Discord</StyledButtonSmall>
                </a>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            justify={"flex-start"}
            alignItems={"center"}
            style={{ marginBottom: "326px" }}
          >
            <Grid item xs={12} md={7}>
              <Box>
                <StyledSubtitle>
                  Egal ob du deine Skills in League of Legends verbessern oder
                  an spannenden Turnieren teilnehmen willst, im{" "}
                  <span>United Cyber Space</span> ist für jeden etwas dabei.
                </StyledSubtitle>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            justify={"flex-end"}
            alignItems={"center"}
            style={{ marginBottom: "129px" }}
          >
            <Grid item xs={12} md={7}>
              <Box>
                <StyledSubtitle>
                  Dein Skill ist <span>egal</span>, mithilfe unserer Coaches
                  erreichst du die nächste Elo
                </StyledSubtitle>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            justify={"flex-start"}
            alignItems={"center"}
            style={{ marginBottom: "147px" }}
          >
            <Grid item xs={12} md={7}>
              <Box>
                <StyledSubtitle>
                  Triff jetzt Gleichgesinnte im coolsten Esports Space direkt in{" "}
                  <span>Hamburg</span>
                </StyledSubtitle>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            justify={"center"}
            alignItems={"center"}
            style={{ marginBottom: "99px" }}
          >
            <Box>
              <a
                href="https://discord.gg/y6uG2Ge"
                target="_blank"
                rel="noopener noreferrer"
              >
                <StyledButtonSmall>Join our Discord</StyledButtonSmall>
              </a>
            </Box>
          </Grid>
          <Grid
            container
            justify={"space-between"}
            alignItems={"center"}
            style={{ marginBottom: "32px" }}
          >
            <Box>
              <h4 style={{ margin: 0 }}>events</h4>
            </Box>
          </Grid>
          <Grid
            container
            spacing={matches ? 9 : 4}
            style={{ marginBottom: "88px" }}
          >
            {events.events.map((singleEvent, index) => {
              if (index < 5) {
                return (
                  <Grid item xs={12} md={6} key={singleEvent.id}>
                    <EventBox singleEvent={singleEvent} />
                  </Grid>
                );
              }
              return null;
            })}
            <Grid item xs={12} md={6}>
              <HeroEventsVertical />
            </Grid>
          </Grid>
          <Grid
            container
            justify={"space-between"}
            alignItems={"center"}
            style={{ marginBottom: "32px" }}
          >
            <Box>
              <h4 style={{ margin: 0 }}>news</h4>
            </Box>
          </Grid>
          <Grid container spacing={4} style={{ marginBottom: "32px" }}>
            {news.news.map((singleNews, index) => {
              if (index < 3) {
                return (
                  <Grid item xs={12} md={4} key={singleNews.id}>
                    <NewsBox singleNews={singleNews} />
                  </Grid>
                );
              }
              return null;
            })}
          </Grid>
          <Grid container justify={"flex-end"} style={{ marginBottom: "88px" }}>
            <Box>
              <Link to="/news">
                <StyledButtonPrimary>see all news</StyledButtonPrimary>
              </Link>
            </Box>
          </Grid>
        </StyledContainer>
      )}
      <Footer />
    </Fragment>
  );
});

export default Home;
