import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";

const StyledWrapper = styled.div`
  position: absolute;
  // min-height: 100vh;
  // min-width: 100vw;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #273032;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledProgress = styled(CircularProgress)`
  color: #0091d4;
`;

const LoaderAbsolute = () => {
  return (
    <StyledWrapper>
      <StyledProgress />
    </StyledWrapper>
  );
};

export default LoaderAbsolute;
