import React, { Fragment } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import styled from "styled-components";
import moment from "moment";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { view } from "@risingstack/react-easy-state";

import { auth, bookings } from "../../stores/BaseStore";
import LoaderAbsolute from "../shared/LoaderAbsolute";

const StyledDialog = styled(Dialog)`
  .MuiBackdrop-root {
    background: #161e20;
    mix-blend-mode: normal;
    opacity: 0.5 !important;
  }

  .MuiPaper-root {
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
    overflow: visible;
  }

  .StripeElement {
    padding: 20px 16px 20px 16px;
    background-color: transparent !important;
    border: 0 !important;
    border-bottom: 1px solid #ffffff !important;
    border-radius: 0;

    outline: none !important;
    margin-bottom: 33px;

    &:focus {
      border: 0;
      border-bottom: 1px solid #0091d4;
      box-shadow: none !important;
    }

    &:hover {
      border-color: #0091d4;
    }
  }
`;

const StyledOuterWrapper = styled(Box)`
  position: relative;
  background: #273032;
  width: 100%;
  z-index: 3;

  h3 {
    margin: 0;
    line-height: 80px;

    span {
      color: #0091d4;
    }
  }

  @media (max-width: 1279px) {
    overflow-y: scroll;
  }
`;

const StyledInnerWrapper = styled(Box)`
  position: relative;
  z-index: 3;
  margin-bottom: 2rem;
`;

const StyledButtonPrimary = styled.button`
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  background-color: #0091d4;
  border: 1px solid #0091d4;
  border-radius: 0;
  padding: 20px;
  cursor: pointer;
  letter-spacing: 1.42857px;
  margin-top: 2rem;

  &:hover {
    opacity: 0.8;
  }

  width: 100%;
`;

const StyledSideWrapper = styled(Box)`
  position: absolute;
  top: 100px;
  left: -136px;
  background-color: #161e20;
  width: 324px;

  @media (max-width: 1279px) {
    position: relative;
    left: unset;
    margin-bottom: 72px;
    width: 100%;
  }
`;

const StyledSideWrapperInner = styled(Box)`
  padding: 32px 59px 32px 32px;
`;

const StyledSideWrapperTitle = styled.p`
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: 0.176471px;
  color: #0091d4;
  margin: 0;
  margin-bottom: 5px;

  @media (max-width: 599px) {
    font-size: 16px;
  }
`;

const StyledCloseButton = styled.span`
  // position: fixed;
  // top: 60px;
  // right: 60px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 4;

  svg {
    font-size: 88px;
    color: #0091d4;

    &:hover {
      opacity: 0.8;
    }
  }

  // @media (max-width: 1279px) {
  //   position: relative;
  //   left: 20px;
  //   top: 100px;
  // }
`;

const StyledSideWrapperSubTitle = styled.p`
  font-family: "AzoSans Black", "Arial", sans-serif;
  font-size: 48px;
  line-height: 48px;
  letter-spacing: 0.352941px;
  margin: 0;
`;

const StyledTrainerName = styled.p`
  font-family: "AzoSans Bold", "Arial", sans-serif;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #0091d4;
  margin: 0;
  margin-bottom: 25px;
`;

const StyledTrainerDesc = styled.p`
  font-family: "AzoSans Regular", "Arial", sans-serif;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4375px;
  color: #a7acb3;
  margin: 0;
`;

const StyledConfigurationName = styled.p`
  font-family: "AzoSans Bold", "Arial", sans-serif;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #a7acb3;
  margin: 0;
  margin-bottom: 25px;
`;

const StyledConfigurationDetails = styled.p`
  font-family: "AzoSans Bold", "Arial", sans-serif;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4375px;
  color: #a7acb3;
  margin: 0;
`;

const StyledDateWrapper = styled(Box)`
  position: absolute;
  top: 0;
  left: 113px;
  height: 88px;
  width: 88px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0091d4;
`;

const StyledDateDay = styled.p`
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 36px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 3.21429px;
  margin: 0;
  margin-bottom: 3px;
`;

const StyledDateMonth = styled.p`
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.128571px;
  letter-spacing: 0.0714286px;
  margin: 0;
`;

const StyledEventTime = styled.p`
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.128571px;
  color: #2690d7;
  margin: 0;
  margin-bottom: 65px;
  margin-top: 35px;

  span {
    color: #ffffff;
  }
`;

const StyledMessageWrapper = styled(Box)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  font-size: 16px;
  padding: 24px;
  text-align: center;
`;

const CARD_OPTIONS = {
  style: {
    base: {
      iconColor: "#ffffff",
      color: "#ffffff",
      fontFamily: "Azo Sans Regular, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      letterSpacing: "0.15px",
    },
  },
};

const PaymentForm = view((props) => {
  const { paymentDialogue, setPaymentDialogue, event } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const stripe = useStripe();
  const elements = useElements();

  const date = moment(event.starts_at).format("D");
  const month = moment(event.starts_at).format("MMM");
  const day = moment(event.starts_at).format("dddd");
  const time = moment(event.starts_at).format("h A");
  const year = moment(event.starts_at).format("YYYY");

  const handleClose = () => {
    setPaymentDialogue(false);
    if (bookings.paymentMessage) {
      bookings.singleBooking = null;
      setTimeout(() => {
        bookings.getUserBooking(event.id);
      }, 3000);
    }
    bookings.paymentMessage = null;
  };

  const handleSubmit = async (e) => {
    // Block native form submission.
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardNumberElement);

    bookings.createPaymentMethod(stripe, cardElement);
    // if (saveCard) {
    //   savePaymentMethod(cardElement);
    // } else {
    //   createPaymentMethod(cardElement);
    // }
  };

  // const handleSelect = (e) => {
  //   // setPaymentMethodId(e);
  // };

  // const handlePayment = () => {
  //   // payForInvoice(paymentMethodId, invoiceId);
  // };

  // autoEffect(() => {
  //   if (!player.isLoading && !player.errors) {
  //     setContactDialogue(false);
  //   }
  // });

  // autoEffect(() => {
  //   if (auth.errors) {
  //     if (formRef.current) {
  //       formRef.current.validateForm();
  //     }
  //   }
  // });

  return auth.user ? (
    <StyledDialog
      fullWidth={true}
      maxWidth={"md"}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={paymentDialogue}
      disableScrollLock={true}
    >
      <StyledOuterWrapper
        px={matches ? 12 : 4}
        pb={bookings.paymentMessage ? "307px" : 12}
      >
        {!bookings.isLoading ? (
          <StyledCloseButton onClick={handleClose}>
            <CloseIcon />
          </StyledCloseButton>
        ) : null}
        {bookings.isLoading ? (
          <LoaderAbsolute />
        ) : bookings.paymentMessage ? (
          <StyledMessageWrapper>{bookings.paymentMessage}</StyledMessageWrapper>
        ) : (
          <Fragment>
            <StyledDateWrapper>
              <StyledDateDay>{date}</StyledDateDay>
              <StyledDateMonth>{month}</StyledDateMonth>
            </StyledDateWrapper>
            <StyledSideWrapper>
              <StyledSideWrapperInner>
                <StyledSideWrapperTitle>seats left</StyledSideWrapperTitle>
                <StyledSideWrapperSubTitle>
                  {event.available_slots ? event.available_slots : 0}
                </StyledSideWrapperSubTitle>
              </StyledSideWrapperInner>
              <StyledSideWrapperInner>
                <StyledSideWrapperTitle>price</StyledSideWrapperTitle>
                <StyledSideWrapperSubTitle>
                  {event.price ? event.price : 0} €
                </StyledSideWrapperSubTitle>
              </StyledSideWrapperInner>
              <StyledSideWrapperInner>
                <StyledSideWrapperTitle>trainer</StyledSideWrapperTitle>
                <StyledTrainerName>
                  {event.trainer_title ? event.trainer_title : "-"}{" "}
                </StyledTrainerName>
                {event.trainer_description ? (
                  <StyledTrainerDesc>
                    {event.trainer_description}
                  </StyledTrainerDesc>
                ) : null}
              </StyledSideWrapperInner>
              <StyledSideWrapperInner>
                <StyledSideWrapperTitle>configuration</StyledSideWrapperTitle>
                <StyledConfigurationName>
                  {event.Venue.configuration ? event.Venue.configuration : "-"}{" "}
                </StyledConfigurationName>
                {event.Venue.computer_configuration ? (
                  <StyledConfigurationDetails>
                    {event.Venue.computer_configuration}
                  </StyledConfigurationDetails>
                ) : null}
              </StyledSideWrapperInner>
            </StyledSideWrapper>
            <Grid container justify="center">
              <Grid item xs={12} md={6} mx="auto">
                <Box>
                  <StyledEventTime>
                    {day} <span>{time}</span> {year}
                  </StyledEventTime>
                </Box>
                <StyledInnerWrapper>
                  <h3>
                    Booking and <span>Payment</span>
                  </h3>
                </StyledInnerWrapper>
                <StyledInnerWrapper>
                  <form onSubmit={handleSubmit}>
                    <div>
                      <label for="cardNumber">Card number</label>
                      <CardNumberElement
                        id="cardNumber"
                        options={CARD_OPTIONS}
                      />
                    </div>
                    <Box>
                      <div>
                        <label for="expirationDate">Expiration date</label>
                        <CardExpiryElement
                          id="expirationDate"
                          options={CARD_OPTIONS}
                        />
                      </div>
                      <div>
                        <label for="cardVerificationCode">
                          Card verification code
                        </label>
                        <CardCvcElement
                          id="cardVerificationCode"
                          options={CARD_OPTIONS}
                        />
                      </div>
                    </Box>
                    {/* <Box>
                  <p>Save card for future use</p>
                  <Checkbox
                    type="checkbox"
                    name="save"
                    checked={saveCard}
                    onChange={(e) => setSaveCard(e.target.checked)}
                  ></Checkbox>
                </Box> */}
                    <StyledButtonPrimary
                      type="primary"
                      htmlType="submit"
                      disabled={!stripe}
                    >
                      Pay {event.price ? `${event.price} €` : null}
                    </StyledButtonPrimary>
                  </form>
                </StyledInnerWrapper>
              </Grid>
            </Grid>
          </Fragment>
        )}
      </StyledOuterWrapper>
    </StyledDialog>
  ) : null;
});

export default PaymentForm;
