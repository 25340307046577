import React from "react";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { view } from "@risingstack/react-easy-state";
import moment from "moment";

// import shareIcon from "../../assets/img/follow_icon.svg";

const StyledLink = styled(Link)`
  all: unset;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

// const StyledShareIcon = styled.span`
//   all: unset;
//   cursor: pointer;

//   &:hover {
//     opacity: 0.8;
//   }
// `;

const StyledDateWrapper = styled(Box)`
  position: absolute;
  top: 0;
  left: 88px;
  height: 52px;
  width: 52px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0091d4;
`;

const StyledDateDay = styled.p`
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 18px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1.60714px;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 3px;
`;

const StyledDateMonth = styled.p`
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.0714286px;
  margin: 0;
`;

const StyledEventWrapper = styled(Box)`
  position: relative;
  background-color: #161e20;
`;

const StyledImageWrapper = styled(Box)`
  height: 256px;
  width: 100%;
  background: url(${(props) => props.image}) no-repeat center;
  background-size: cover;
`;

const StyledInfoWrapper = styled(Box)`
  padding: 40px 88px 44px 88px;

  @media (max-width: 599px) {
    padding: 40px;
  }
`;

const StyledEventHeading = styled.p`
  font-family: "AzoSans Black", "Arial", sans-serif;
  font-size: 21px;
  line-height: 32px;
  letter-spacing: 0.154412px;
  margin: 0;
  margin-bottom: 11px;
`;

const StyledEventSubtitle = styled.p`
  font-size: 11px;
  line-height: 24px;
  letter-spacing: 0.0785714px;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 19px;
`;

const StyledButtonWrapper = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const StyledButtonSecondary = styled.button`
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid #fafafa;
  border-radius: 0;
  padding: 20px;
  cursor: pointer;
  letter-spacing: 1.42857px;
  // margin-right: 56px;

  &:hover {
    opacity: 0.8;
  }

  width: 250px;
`;

const EventBox = view((props) => {
  const { singleEvent } = props;

  const date = moment(singleEvent.starts_at).format("D");
  const month = moment(singleEvent.starts_at).format("MMM");

  return (
    <StyledLink to={`/events/${singleEvent.id}`}>
      <StyledEventWrapper>
        <StyledDateWrapper>
          <StyledDateDay>{date}</StyledDateDay>
          <StyledDateMonth>{month}</StyledDateMonth>
        </StyledDateWrapper>
        <StyledImageWrapper image={singleEvent.image} />
        <StyledInfoWrapper>
          <StyledEventHeading>{singleEvent.title}</StyledEventHeading>
          <StyledEventSubtitle>
            {singleEvent.venue
              ? `${singleEvent.venue.title}, ${singleEvent.venue.address_line_1}, ${singleEvent.venue.zip}, ${singleEvent.venue.city}`
              : "online"}
          </StyledEventSubtitle>
          <StyledButtonWrapper>
            <StyledButtonSecondary>book event</StyledButtonSecondary>
            {/* <StyledShareIcon>
              <img src={shareIcon} alt="share_icon" />
            </StyledShareIcon> */}
          </StyledButtonWrapper>
        </StyledInfoWrapper>
      </StyledEventWrapper>
    </StyledLink>
  );
});

export default EventBox;
