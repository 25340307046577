import React, { Fragment, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import styled from "styled-components";
import { view } from "@risingstack/react-easy-state";
import moment from "moment";
import { Link, useParams } from "react-router-dom";

// import followIcon from "../../assets/img/follow_icon.svg";

import { news } from "../../stores/BaseStore";
import Loader from "../shared/Loader";
import HeroEvents from "../shared/HeroEvents";
import Footer from "../shared/Footer";
import NewsBox from "../news/NewsBox";

const StyledContainer = styled(Container)`
  position: relative;
  z-index: 2;
  padding-top: 440px;

  @media (max-width: 959px) {
    padding-top: 230px;
  }
`;

const StyledNewsWrapper = styled(Box)`
  position: relative;
  background-color: #273032;
  padding: 34px 174px;

  @media (max-width: 959px) {
    padding: 136px 24px 34px 24px;
  }
`;

// const StyledShareIcon = styled(Box)`
//   position: absolute;
//   top: 32px;
//   right: 26px;
//   cursor: pointer;

//   &:hover {
//     opacity: 0.8;
//   }
// `;

const StyledDateWrapper = styled(Box)`
  position: absolute;
  top: 0;
  left: 42px;
  height: 88px;
  width: 88px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0091d4;
`;

const StyledDateDay = styled.p`
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 36px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 3.21429px;
  margin: 0;
  margin-bottom: 3px;
`;

const StyledDateMonth = styled.p`
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.128571px;
  letter-spacing: 0.0714286px;
  margin: 0;
`;

const StyledNewsTime = styled.p`
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.128571px;
  color: #2690d7;
  margin: 0;
  margin-bottom: 13px;

  span {
    color: #ffffff;
  }
`;

const StyledNewsTitle = styled.p`
  font-family: "AzoSans Black", "Arial", sans-serif;
  font-size: 48px;
  line-height: 80px;
  letter-spacing: 0.352941px;
  margin: 0;
  margin-bottom: 39px;

  @media (max-width: 599px) {
    font-size: 32px;
    line-height: unset;
  }
`;

const StyledNewsIntro = styled.p`
  font-family: "AzoSans Bold", "Arial", sans-serif;
  font-size: 21px;
  line-height: 40px;
  letter-spacing: 0.154412px;
  margin: 0;
  margin-bottom: 20px;
`;

const StyledNewsText = styled.p`
  font-family: "AzoSans Regular", "Arial", sans-serif;
  font-size: 16px;
  line-height: 28px;
  text-align: justify;
  letter-spacing: 0.117647px;
  color: #a7acb3;
  margin: 0;
  margin-bottom: 68px;
`;

const StyledBackButton = styled(Link)`
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 16px;
  line-height: 12px;
  letter-spacing: 1.42857px;
  color: #ffffff;
  margin-bottom: 42px;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  svg {
    margin-right: 26px;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const SingleNews = view((props) => {
  const params = useParams();
  const { id } = params;

  let date;
  let month;
  let day;
  let time;
  let year;

  useEffect(() => {
    news.getNews();
    news.getSingleNews(id);
    // eslint-disable-next-line
  }, [id]);

  if (news.singleNews) {
    date = moment(news.singleNews.created_at).format("D");
    month = moment(news.singleNews.created_at).format("MMM");
    day = moment(news.singleNews.created_at).format("dddd");
    time = moment(news.singleNews.created_at).format("h A");
    year = moment(news.singleNews.created_at).format("YYYY");
  }

  return (
    <Fragment>
      <StyledContainer fixed>
        {!news.news || !news.singleNews ? (
          <Fragment>
            <StyledSpacer />
            <Loader />
          </Fragment>
        ) : (
          <Fragment>
            <Grid
              container
              justify={"center"}
              alignItems={"center"}
              style={{ marginBottom: "86px" }}
            >
              <Grid item xs={12} lg={11}>
                <StyledNewsWrapper>
                  <StyledDateWrapper>
                    <StyledDateDay>{date}</StyledDateDay>
                    <StyledDateMonth>{month}</StyledDateMonth>
                  </StyledDateWrapper>
                  {/* <StyledShareIcon>
                    <img src={followIcon} alt="follow_icon" />
                  </StyledShareIcon> */}
                  <StyledNewsTime>
                    {day} <span>{time}</span> {year}
                  </StyledNewsTime>
                  <StyledNewsTitle>{news.singleNews.title}</StyledNewsTitle>
                  <StyledNewsIntro>
                    {news.singleNews.short_description}
                  </StyledNewsIntro>
                  <StyledNewsText>{news.singleNews.text}</StyledNewsText>
                  <StyledBackButton to="/news">
                    <ArrowBackIcon fontSize="small" />
                    <span>back to news</span>
                  </StyledBackButton>
                </StyledNewsWrapper>
              </Grid>
            </Grid>
            <Grid
              container
              justify={"space-between"}
              alignItems={"center"}
              style={{ marginBottom: "32px" }}
            >
              <Box>
                <h4 style={{ margin: 0 }}>you may also like</h4>
              </Box>
            </Grid>
            <Grid container spacing={4} style={{ marginBottom: "32px" }}>
              {news.news.map((singleNews, index) => {
                if (index < 3 && singleNews.id !== news.singleNews.id) {
                  return (
                    <Grid item xs={12} md={4} key={singleNews.id}>
                      <NewsBox singleNews={singleNews} />
                    </Grid>
                  );
                }
                return null;
              })}
            </Grid>
            <HeroEvents />
          </Fragment>
        )}
      </StyledContainer>
      <Footer />
    </Fragment>
  );
});

export default SingleNews;
