import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledButtonPrimary = styled.button`
  width: 100%;
  color: #ffffff;
  background-color: #0091d4;
  border: 1px solid #0091d4;
  border-radius: 0;
  padding: 30px;
  cursor: pointer;
  font-size: 24px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 2.14286px;

  &:hover {
    opacity: 0.8;
  }

  width: 400px;

  @media (max-width: 959px) {
    width: 100%;
    line-height: normal;
  }
`;

const StyledTextWrapper = styled(Box)`
  h2 {
    span {
      color: #0091d4;
    }
  }
`;

const StyledButtonWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 959px) {
    justify-content: flex-start;
  }
`;

const HeroNews = () => {
  return (
    <Grid
      container
      justify={"space-between"}
      alignItems={"center"}
      style={{ marginBottom: "96px" }}
    >
      <Grid item xs={12} md={6}>
        <StyledTextWrapper>
          <h2>
            Stay up to date with our latest <span>news</span>
          </h2>
        </StyledTextWrapper>
      </Grid>
      <Grid item xs={12} md={6}>
        <StyledButtonWrapper>
          <Link to="/news">
            <StyledButtonPrimary>See all news</StyledButtonPrimary>
          </Link>
        </StyledButtonWrapper>
      </Grid>
    </Grid>
  );
};

export default HeroNews;
