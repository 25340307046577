import React, { useRef } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import CloseIcon from "@material-ui/icons/Close";
import { view, autoEffect } from "@risingstack/react-easy-state";

import { auth } from "../../stores/BaseStore";
import Loader from "../shared/Loader";

// const StyledDarkOverlay = styled(Box)`
//   position: absolute;
//   height: 100%;
//   width: 100%;
//   top: 0;
//   left: 0;
//   background: #0f1a26;
//   mix-blend-mode: normal;
//   opacity: 0.3;
//   z-index: 2;
// `;

// const StyledGradientOverlay = styled(Box)`
//   position: absolute;
//   height: 100%;
//   width: 100%;
//   top: 0;
//   left: 0;
//   background: linear-gradient(
//     180deg,
//     rgba(226, 6, 127, 0.5) 0%,
//     rgba(49, 0, 67, 0.5) 100%
//   );
//   mix-blend-mode: normal;
//   transform: matrix(1, 0, 0, -1, 0, 0);
//   opacity: 0.78;
//   z-index: 1;
// `;

const StyledDialog = styled(Dialog)`
  .MuiBackdrop-root {
    background: #161e20;
    mix-blend-mode: normal;
    opacity: 0.5 !important;
  }

  .MuiPaper-root {
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
  }
`;

const StyledOuterWrapper = styled(Box)`
  position: relative;
  background: #273032;
  width: 100%;
  z-index: 3;

  h3 {
    margin: 0;
    line-height: 80px;

    span {
      color: #0091d4;
    }
  }

  @media (max-width: 1279px) {
    overflow-y: scroll;
  }
`;

const StyledInnerWrapper = styled(Box)`
  position: relative;
  z-index: 3;
  margin-bottom: 2rem;
`;

const StyledField = styled(Field)`
  width: 100%;
  margin-bottom: 3rem;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s;
    -webkit-text-fill-color: #a7acb3 !important;
  }

  input:-webkit-autofill::first-line {
    font-size: 16px;
  }

  .MuiInput-input {
    font-family: "AzoSans Regular", "Arial", sans-serif;
    font-size: 16;
    line-height: 24px;
    letter-spacing: 0.15px;
    padding: 20px 16px 20px 16px;
  }

  .MuiInputLabel-root {
    font-family: "AzoSans Bold", "Arial", sans-serif;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.25px;
    padding-left: 20px;
  }

  .MuiFormHelperText-root {
    position: absolute;
    bottom: -30px;
    left: 16px;
  }

  .MuiInput-underline::before {
    border-bottom: 1px solid #ffffff;
  }

  .MuiInput-underline:hover:not(.Mui-disabled)::before {
    border-bottom: 1px solid #ffffff;
  }

  .MuiInput-underline::after {
    border-bottom: 1px solid #0091d4;
  }

  .MuiFormLabel-root {
    font-family: "Polaris", "Arial", sans-serif;
    color: #ffffff;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #0091d4;
  }
`;

const StyledButtonPrimary = styled.button`
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  background-color: #0091d4;
  border: 1px solid #0091d4;
  border-radius: 0;
  padding: 20px;
  cursor: pointer;
  letter-spacing: 1.42857px;
  margin-top: 2rem;

  &:hover {
    opacity: 0.8;
  }

  width: 100%;
`;

const StyledInputWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;

  @media (max-width: 599px) {
    flex-wrap: wrap;
  }
`;

const StyledCloseButton = styled.span`
  // position: fixed;
  // top: 60px;
  // right: 60px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 4;

  svg {
    font-size: 88px;
    color: #0091d4;

    &:hover {
      opacity: 0.8;
    }
  }

  // @media (max-width: 1279px) {
  //   position: relative;
  //   left: 20px;
  //   top: 100px;
  // }
`;

const ProfileEdit = view((props) => {
  const { profileDialogue, setProfileDialogue } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const formRef = useRef();

  const handleClose = () => {
    setProfileDialogue(false);
  };

  autoEffect(() => {
    if (!auth.isLoading && !auth.errors) {
      setProfileDialogue(false);
    }
  });

  autoEffect(() => {
    if (auth.errors) {
      if (formRef.current) {
        formRef.current.validateForm();
      }
    }
  });

  // const regex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

  const customValidation = (values) => {
    let errors = {};

    // if (values.phone_number && !/^[+]?[0-9]{4,16}$/.test(values.phone_number)) {
    //   errors.phone_number = "Not a valid phone number";
    // } else if (
    //   auth.errors &&
    //   auth.errors.message === "Invalid phone number format."
    // ) {
    //   auth.errors.message = "";
    //   errors.phone_number = "Not a valid phone number";
    // } else {
    //   errors = {};
    // }

    return errors;
  };

  return auth.user ? (
    <StyledDialog
      fullWidth={true}
      maxWidth={"md"}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={profileDialogue}
      disableScrollLock={true}
    >
      <StyledOuterWrapper px={matches ? 12 : 4} py={12}>
        <StyledCloseButton onClick={handleClose}>
          <CloseIcon />
        </StyledCloseButton>
        <Grid container justify="center">
          <Grid item xs={12} md={6} mx="auto">
            <StyledInnerWrapper>
              <h3>
                <span>Edit</span> Account Details
              </h3>
            </StyledInnerWrapper>
            <StyledInnerWrapper>
              <Formik
                initialValues={{
                  name: auth.user.attributes.name || "",
                  street: auth.user.attributes["custom:street"] || "",
                  zip: auth.user.attributes["custom:zip"] || "",
                  city: auth.user.attributes["custom:city"] || "",
                  country: auth.user.attributes["custom:country"] || "",
                  /* phone_number: auth.user.attributes.phone_number || "", */
                }}
                validate={customValidation}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    setSubmitting(false);
                    auth.updateUser(values);
                  }, 500);
                }}
                innerRef={formRef}
              >
                {({ submitForm, isSubmitting }) => (
                  <Form>
                    <StyledField
                      component={TextField}
                      name="name"
                      label="Full name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      autoComplete="off"
                    />
                    <br />
                    <StyledField
                      component={TextField}
                      label="Street name and number"
                      name="street"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      autoComplete="off"
                    />
                    <StyledField
                      component={TextField}
                      label="ZIP code"
                      name="zip"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      autoComplete="off"
                    />
                    <StyledInputWrapper>
                      <StyledField
                        component={TextField}
                        label="City"
                        name="city"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        style={{ marginRight: "2rem" }}
                      />
                      <StyledField
                        component={TextField}
                        label="Country"
                        name="country"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                      />
                    </StyledInputWrapper>
                    {/* <StyledField
                      component={TextField}
                      label="Phone"
                      name="phone_number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      autoComplete="off"
                    /> */}
                    {isSubmitting && <Loader />}
                    <br />
                    <StyledButtonPrimary
                      type="button"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      Save
                    </StyledButtonPrimary>
                  </Form>
                )}
              </Formik>
            </StyledInnerWrapper>
          </Grid>
        </Grid>
      </StyledOuterWrapper>
    </StyledDialog>
  ) : null;
});

export default ProfileEdit;
