import React, { Fragment, useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import { view } from "@risingstack/react-easy-state";

import { auth, player } from "../../stores/BaseStore";
import PlayerBoxLol from "../players/PlayerBoxLol";
import PlayersTableLol from "../players/PlayersTableLol";
import PlayerBoxDota from "../players/PlayerBoxDota";
import PlayersTableDota from "../players/PlayersTableDota";
import HeroEvents from "../shared/HeroEvents";
import Loader from "../shared/Loader";
import Footer from "../shared/Footer";

const StyledContainer = styled(Container)`
  position: relative;
  z-index: 2;
  padding-top: 230px;
`;

const StyledTitleWrapper = styled(Box)`
  p {
    font-size: 21px;
    line-height: 32px;
    letter-spacing: 0.154412px;
    margin-bottom: 0;
  }

  h4 {
    text-transform: unset;
  }

  @media (max-width: 959px) {
    margin-bottom: 24px;
  }
`;

const StyledButtonSecondary = styled.button`
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid #fafafa;
  border-radius: 0;
  padding: 20px;
  cursor: pointer;
  letter-spacing: 1.42857px;
  outline: 0;

  &:hover {
    opacity: 0.8;
  }

  width: 150px;

  &.active {
    background-color: #0091d4;
    border: 1px solid #0091d4;
  }

  @media (max-width: 599px) {
    margin-bottom: 24px;
  }
`;

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const Players = view(() => {
  const [game, setGame] = useState("lol");

  useEffect(() => {
    if (game === "lol") {
      player.playerDetailsLol = null;
      player.getPlayersLol();
    } else {
      player.playerDetailsDota = null;
      player.getPlayersDota();
    }
  }, [game]);

  return (
    <Fragment>
      <StyledContainer fixed>
        {game === "lol" ? (
          !auth.user || !player.playersLol ? (
            <Fragment>
              <StyledSpacer />
              <Loader />
            </Fragment>
          ) : (
            <Fragment>
              <Grid
                container
                justify={"space-between"}
                alignItems={"center"}
                style={{ marginBottom: "102px" }}
              >
                <StyledTitleWrapper>
                  <h4 style={{ margin: 0 }}>Players</h4>
                  <p>Rankings are updated periodically.</p>
                </StyledTitleWrapper>
                <Box>
                  <StyledButtonSecondary
                    style={{ marginRight: "36px" }}
                    onClick={() => {
                      setGame("lol");
                    }}
                    className={game === "lol" ? "active" : null}
                  >
                    lol
                  </StyledButtonSecondary>
                  <StyledButtonSecondary
                    onClick={() => {
                      setGame("dota");
                    }}
                    className={game === "dota" ? "active" : null}
                  >
                    dota
                  </StyledButtonSecondary>
                </Box>
              </Grid>
              <Grid container spacing={4}>
                {player.playersLol.map((singlePlayer, index) => {
                  if (index < 3) {
                    return (
                      <Grid item xs={12} lg={4} key={singlePlayer.id}>
                        <PlayerBoxLol singlePlayer={singlePlayer} />
                      </Grid>
                    );
                  }
                  return null;
                })}
              </Grid>
              <PlayersTableLol />
              <HeroEvents />
            </Fragment>
          )
        ) : !auth.user || !player.playersDota ? (
          <Fragment>
            <StyledSpacer />
            <Loader />
          </Fragment>
        ) : (
          <Fragment>
            <Grid
              container
              justify={"space-between"}
              alignItems={"center"}
              style={{ marginBottom: "102px" }}
            >
              <StyledTitleWrapper>
                <h4 style={{ margin: 0 }}>Players</h4>
                <p>Rankings are updated periodically.</p>
              </StyledTitleWrapper>
              <Box>
                <StyledButtonSecondary
                  style={{ marginRight: "36px" }}
                  onClick={() => {
                    setGame("lol");
                  }}
                  className={game === "lol" ? "active" : null}
                >
                  lol
                </StyledButtonSecondary>
                <StyledButtonSecondary
                  onClick={() => {
                    setGame("dota");
                  }}
                  className={game === "dota" ? "active" : null}
                >
                  dota
                </StyledButtonSecondary>
              </Box>
            </Grid>
            <Grid container spacing={4}>
              {player.playersDota.map((singlePlayer, index) => {
                if (index < 3) {
                  return (
                    <Grid item xs={12} lg={4} key={singlePlayer.id}>
                      <PlayerBoxDota singlePlayer={singlePlayer} />
                    </Grid>
                  );
                }
                return null;
              })}
            </Grid>
            <PlayersTableDota />
            <HeroEvents />
          </Fragment>
        )}
      </StyledContainer>
      <Footer />
    </Fragment>
  );
});

export default Players;
