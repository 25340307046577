import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledWrapper = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 44px 60px 44px 60px;
`;

const StyledButtonPrimary = styled.button`
  width: 100%;
  color: #ffffff;
  background-color: #0091d4;
  border: 1px solid #0091d4;
  border-radius: 0;
  padding: 30px;
  cursor: pointer;
  font-size: 24px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 2.14286px;

  &:hover {
    opacity: 0.8;
  }

  width: 100%;

  @media (max-width: 959px) {
    width: 100%;
    line-height: normal;
  }
`;

const StyledTextWrapper = styled(Box)`
  h2 {
    margin: 0;
    margin-bottom: 134px;

    span {
      color: #0091d4;
    }
  }
`;

const StyledButtonWrapper = styled(Box)`
  width: 100%;
`;

const HeroEventsVertical = () => {
  return (
    <Grid
      container
      justify={"space-between"}
      direction={"column"}
      alignItems={"center"}
    >
      <Grid item xs={12}>
        <StyledWrapper>
          <StyledTextWrapper>
            <h2>
              Stay up to date with our latest <span>events</span>
            </h2>
          </StyledTextWrapper>
          <StyledButtonWrapper>
            <a
              href="https://www.eventbrite.de/o/united-cyber-spaces-31263886927"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StyledButtonPrimary>See all events</StyledButtonPrimary>
            </a>
          </StyledButtonWrapper>
        </StyledWrapper>
      </Grid>
    </Grid>
  );
};

export default HeroEventsVertical;
