import React, { Fragment, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { view } from "@risingstack/react-easy-state";

import { auth, player } from "../../stores/BaseStore";
import Loader from "../shared/Loader";
import Footer from "../shared/Footer";
import SearchLol from "../shared/SearchLol";
import SearchDota from "../shared/SearchDota";
import PlayerBoxLol from "../players/PlayerBoxLol";
import PlayerBoxDota from "../players/PlayerBoxDota";

const StyledContainer = styled(Container)`
  position: relative;
  z-index: 2;
  padding-top: 230px;
`;

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const StyledTitleWrapper = styled(Box)`
  p {
    font-size: 21px;
    line-height: 32px;
    letter-spacing: 0.154412px;
    margin-bottom: 23px;
  }

  h4 {
    text-transform: unset;

    span {
      color: #0091d4;
    }
  }
`;

const SearchResults = view(() => {
  const params = useParams();
  const { game, name } = params;

  useEffect(() => {
    if (game === "lol") {
      player.searchPlayerLol(name);
    } else {
      player.searchPlayerDota(name);
    }
    // eslint-disable-next-line
  }, [game]);

  return (
    <Fragment>
      <StyledContainer fixed>
        {game === "lol" ? (
          !auth.user || !player.searchedPlayerLol ? (
            <Fragment>
              <StyledSpacer />
              <Loader />
            </Fragment>
          ) : (
            <Fragment>
              <Grid
                container
                justify={"space-between"}
                alignItems={"center"}
                style={{ marginBottom: "102px" }}
              >
                <StyledTitleWrapper>
                  <h4 style={{ margin: 0 }}>
                    search results -{" "}
                    <span>{player.searchedPlayerLol.length}</span>
                  </h4>
                  <p>Explore players by Name.</p>
                  <SearchLol />
                </StyledTitleWrapper>
              </Grid>
              <Grid
                container
                spacing={4}
                style={{ marginBottom: "88px", minHeight: "368px" }}
              >
                {player.searchedPlayerLol.map((singlePlayer, index) => {
                  if (index < 3) {
                    return (
                      <Grid item xs={12} lg={4} key={singlePlayer.id}>
                        <PlayerBoxLol singlePlayer={singlePlayer} />
                      </Grid>
                    );
                  }
                  return null;
                })}
              </Grid>
            </Fragment>
          )
        ) : !auth.user || !player.searchedPlayerDota ? (
          <Fragment>
            <StyledSpacer />
            <Loader />
          </Fragment>
        ) : (
          <Fragment>
            <Fragment>
              <Grid
                container
                justify={"space-between"}
                alignItems={"center"}
                style={{ marginBottom: "102px" }}
              >
                <StyledTitleWrapper>
                  <h4 style={{ margin: 0 }}>
                    search results -{" "}
                    <span>{player.searchedPlayerDota.length}</span>
                  </h4>
                  <p>Explore players by Name.</p>
                  <SearchDota />
                </StyledTitleWrapper>
              </Grid>
              <Grid
                container
                spacing={4}
                style={{ marginBottom: "88px", minHeight: "368px" }}
              >
                {player.searchedPlayerDota.map((singlePlayer, index) => {
                  if (index < 3) {
                    return (
                      <Grid item xs={12} lg={4} key={singlePlayer.id}>
                        <PlayerBoxDota singlePlayer={singlePlayer} />
                      </Grid>
                    );
                  }
                  return null;
                })}
              </Grid>
            </Fragment>
          </Fragment>
        )}
      </StyledContainer>
      <Footer />
    </Fragment>
  );
});

export default SearchResults;
