import React, { Fragment, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { view } from "@risingstack/react-easy-state";

import { auth, player, events } from "../../stores/BaseStore";
import Loader from "../shared/Loader";
import HeroEvents from "../shared/HeroEvents";
import Footer from "../shared/Footer";
import PlayerDetailsLol from "../players/PlayerDetailsLol";
import PlayerDetailsDota from "../players/PlayerDetailsDota";

const StyledContainer = styled(Container)`
  position: relative;
  z-index: 2;
  padding-top: 230px;
`;

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const Player = view(() => {
  const params = useParams();
  const { game, id } = params;

  useEffect(() => {
    events.getEvents();

    if (game === "lol") {
      player.getPlayerLol(id);
    } else {
      player.getPlayerDota(id);
    }

    // eslint-disable-next-line
  }, [game]);

  return (
    <Fragment>
      <StyledContainer fixed>
        {game === "lol" ? (
          !auth.user || !player.playerDetailsLol || !events.events ? (
            <Fragment>
              <StyledSpacer />
              <Loader />
            </Fragment>
          ) : (
            <Fragment>
              <PlayerDetailsLol playerDetails={player.playerDetailsLol} />
              <HeroEvents />
            </Fragment>
          )
        ) : !auth.user || !player.playerDetailsDota ? (
          <Fragment>
            <StyledSpacer />
            <Loader />
          </Fragment>
        ) : (
          <Fragment>
            <PlayerDetailsDota playerDetails={player.playerDetailsDota} />
            <HeroEvents />
          </Fragment>
        )}
      </StyledContainer>
      <Footer />
    </Fragment>
  );
});

export default Player;
