import React, { useRef, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { Select } from "formik-material-ui";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import CloseIcon from "@material-ui/icons/Close";
import { view, autoEffect } from "@risingstack/react-easy-state";

import { auth } from "../../stores/BaseStore";
import { player } from "../../stores/BaseStore";
import Loader from "../shared/Loader";

const StyledDialog = styled(Dialog)`
  .MuiBackdrop-root {
    background: #161e20;
    mix-blend-mode: normal;
    opacity: 0.5 !important;
  }

  .MuiPaper-root {
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
  }
`;

const StyledOuterWrapper = styled(Box)`
  position: relative;
  background: #273032;
  width: 100%;
  z-index: 3;

  h3 {
    margin: 0;
    line-height: 80px;

    span {
      color: #0091d4;
    }
  }

  @media (max-width: 1279px) {
    overflow-y: scroll;
  }
`;

const StyledInnerWrapper = styled(Box)`
  position: relative;
  z-index: 3;
  margin-bottom: 2rem;
`;

const StyledFormControl = styled(FormControl)`
  width: 100%;

  .MuiFormLabel-root {
    font-family: "Polaris", "Arial", sans-serif;
    color: #ffffff;
    font-size: 14px;
    line-height: 20px;
    -webkit-letter-spacing: 0.25px;
    -moz-letter-spacing: 0.25px;
    -ms-letter-spacing: 0.25px;
    letter-spacing: 0.25px;
    padding-left: 20px;
  }

  .MuiInput-underline::before {
    border-bottom: 1px solid #ffffff;
  }

  .MuiInput-underline:hover:not(.Mui-disabled)::before {
    border-bottom: 1px solid #ffffff;
  }

  .MuiInput-underline::after {
    border-bottom: 1px solid #0091d4;
  }

  .MuiSelect-select:focus {
    background-color: transparent;
  }

  .MuiFormHelperText-root {
    position: absolute;
    bottom: 18px;
    left: 16px;
    color: #f44336;
  }
`;

const StyledField = styled(Field)`
  width: 100%;
  margin-bottom: 3rem;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s;
    -webkit-text-fill-color: #a7acb3 !important;
  }

  input:-webkit-autofill::first-line {
    font-size: 16px;
  }

  .MuiInput-input {
    font-family: "AzoSans Regular", "Arial", sans-serif;
    font-size: 16;
    line-height: 24px;
    letter-spacing: 0.15px;
    padding: 20px 16px 20px 16px;
  }

  .MuiInputLabel-root {
    font-family: "AzoSans Bold", "Arial", sans-serif;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.25px;
    padding-left: 20px;
  }

  .MuiFormHelperText-root {
    position: absolute;
    bottom: -30px;
    left: 16px;
  }

  .MuiInput-underline::before {
    border-bottom: 1px solid #ffffff;
  }

  .MuiInput-underline:hover:not(.Mui-disabled)::before {
    border-bottom: 1px solid #ffffff;
  }

  .MuiInput-underline::after {
    border-bottom: 1px solid #0091d4;
  }

  .MuiFormLabel-root {
    font-family: "Polaris", "Arial", sans-serif;
    color: #ffffff;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #0091d4;
  }
`;

const StyledButtonPrimary = styled.button`
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  background-color: #0091d4;
  border: 1px solid #0091d4;
  border-radius: 0;
  padding: 20px;
  cursor: pointer;
  letter-spacing: 1.42857px;
  margin-top: 2rem;

  &:hover {
    opacity: 0.8;
  }

  width: 100%;
`;

const StyledCloseButton = styled.span`
  // position: fixed;
  // top: 60px;
  // right: 60px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 4;

  svg {
    font-size: 88px;
    color: #0091d4;

    &:hover {
      opacity: 0.8;
    }
  }

  // @media (max-width: 1279px) {
  //   position: relative;
  //   left: 20px;
  //   top: 100px;
  // }
`;

const PlayerEditLol = view((props) => {
  const { lolDialogue, setLolDialogue } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const formRef = useRef();
  const [hasServerError, setHasServerError] = useState(false);
  const [hasPositionError, setHasPositionError] = useState(false);
  const [editMode, setEditMode] = useState(false);

  autoEffect(() => {
    if (player.playerLol) {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  });

  const handleClose = () => {
    setLolDialogue(false);
  };

  autoEffect(() => {
    if (!player.isLoading && !player.errors) {
      setLolDialogue(false);
    }
  });

  autoEffect(() => {
    if (player.errors) {
      if (formRef.current) {
        formRef.current.validateForm();
      }
    }
  });

  const customValidation = (values) => {
    let errors = {};

    if (!values.summoner_name) {
      errors.summoner_name = "Summoner name is required";
    } else if (player.errors && player.errors.summoner_name[0] !== "") {
      errors.summoner_name = player.errors.summoner_name[0];
      player.errors.summoner_name[0] = "";
    } else {
      errors = {};
    }

    if (!values.server) {
      // errors.server = "Server is required";
      setHasServerError(true);
    } else {
      setHasServerError(false);
    }

    if (!values.preferred_position) {
      setHasPositionError(true);
    } else {
      setHasPositionError(false);
    }

    return errors;
  };

  return auth.user ? (
    <StyledDialog
      fullWidth={true}
      maxWidth={"md"}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={lolDialogue}
      disableScrollLock={true}
    >
      <StyledOuterWrapper px={matches ? 12 : 4} py={12}>
        <StyledCloseButton onClick={handleClose}>
          <CloseIcon />
        </StyledCloseButton>
        <Grid container justify="center">
          <Grid item xs={12} md={6} mx="auto">
            <StyledInnerWrapper>
              <h3>
                <span>Edit</span> Account Details
              </h3>
            </StyledInnerWrapper>
            <StyledInnerWrapper>
              <Formik
                initialValues={{
                  summoner_name: editMode ? player.playerLol.summoner_name : "",
                  server: editMode ? player.playerLol.server : "",
                  preferred_position: editMode
                    ? player.playerLol.preferred_position
                    : "",
                }}
                validate={customValidation}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    setSubmitting(false);
                    editMode
                      ? player.updatePlayerLol(player.playerLol.id, values)
                      : player.addPlayerLol(values);
                  }, 500);
                }}
                innerRef={formRef}
              >
                {({ submitForm, isSubmitting }) => (
                  <Form>
                    <StyledField
                      component={TextField}
                      name="summoner_name"
                      label="Summoner name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      autoComplete="off"
                      disabled={editMode ? true : false}
                    />
                    <br />
                    <StyledFormControl>
                      <InputLabel htmlFor="serverg" shrink={true}>
                        Server
                      </InputLabel>
                      <StyledField
                        component={Select}
                        name="server"
                        label="Server"
                        inputProps={{
                          id: "serverg",
                        }}
                        autoComplete="off"
                        disabled={editMode ? true : false}
                      >
                        <MenuItem value={"br1"}>br1</MenuItem>
                        <MenuItem value={"eun1"}>eun1</MenuItem>
                        <MenuItem value={"euw1"}>euw1</MenuItem>
                        <MenuItem value={"la1"}>la1</MenuItem>
                        <MenuItem value={"la2"}>la2</MenuItem>
                        <MenuItem value={"na1"}>na1</MenuItem>
                        <MenuItem value={"oc1"}>oc1</MenuItem>
                        <MenuItem value={"ru"}>ru</MenuItem>
                        <MenuItem value={"tr1"}>tr1</MenuItem>
                        <MenuItem value={"jp1"}>jp1</MenuItem>
                        <MenuItem value={"kr"}>kr</MenuItem>
                      </StyledField>
                      {hasServerError && (
                        <FormHelperText>Server is required</FormHelperText>
                      )}
                    </StyledFormControl>
                    <StyledFormControl>
                      <InputLabel htmlFor="position" shrink={true}>
                        Preferred position
                      </InputLabel>
                      <StyledField
                        component={Select}
                        name="preferred_position"
                        label="Preferred position"
                        inputProps={{
                          id: "position",
                        }}
                        autoComplete="off"
                      >
                        <MenuItem value={"top lane"}>Top lane</MenuItem>
                        <MenuItem value={"mid lane"}>Mid lane</MenuItem>
                        <MenuItem value={"jungle"}>Jungle</MenuItem>
                        <MenuItem value={"adc"}>Adc</MenuItem>
                        <MenuItem value={"support"}>Support</MenuItem>
                      </StyledField>
                      {hasPositionError && (
                        <FormHelperText>
                          Preferred position is required
                        </FormHelperText>
                      )}
                    </StyledFormControl>

                    {isSubmitting && <Loader />}
                    <br />
                    <StyledButtonPrimary
                      type="button"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      Save
                    </StyledButtonPrimary>
                  </Form>
                )}
              </Formik>
            </StyledInnerWrapper>
          </Grid>
        </Grid>
      </StyledOuterWrapper>
    </StyledDialog>
  ) : null;
});

export default PlayerEditLol;
