import React from "react";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { view } from "@risingstack/react-easy-state";
import moment from "moment";

import divider from "../../assets/img/divider.svg";

const StyledLink = styled(Link)`
  all: unset;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const StyledDateWrapper = styled(Box)`
  position: absolute;
  top: 0;
  left: 24px;
  height: 52px;
  width: 52px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0091d4;
`;

const StyledDateDay = styled.p`
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 18px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1.60714px;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 3px;
`;

const StyledDateMonth = styled.p`
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.0714286px;
  margin: 0;
`;

const StyledImageWrapper = styled(Box)`
  height: 256px;
  width: 100%;
  background: url(${(props) => props.image}) no-repeat center;
  background-size: cover;
`;

const StyledNewsWrapper = styled(Box)`
  position: relative;
`;

const StyledInfoWrapper = styled(Box)`
  padding: 34px 24px 34px 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const StyledDivider = styled.img`
  margin-bottom: 20px;
`;

const StyledEventHeading = styled.p`
  font-family: "AzoSans Black", "Arial", sans-serif;
  font-size: 21px;
  line-height: 32px;
  letter-spacing: 0.154412px;
  margin: 0;
  margin-bottom: 20px;
`;

const StyledEventDesc = styled.p`
  font-family: "AzoSans Regular", "Arial", sans-serif;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.154412px;
  margin: 0;
  margin-bottom: 11px;
`;

const NewsBox = view((props) => {
  const { singleNews } = props;

  const date = moment(singleNews.created_at).format("D");
  const month = moment(singleNews.created_at).format("MMM");

  return (
    <StyledLink to={`/news/${singleNews.id}`}>
      <StyledNewsWrapper>
        <StyledDateWrapper>
          <StyledDateDay>{date}</StyledDateDay>
          <StyledDateMonth>{month}</StyledDateMonth>
        </StyledDateWrapper>
        <StyledImageWrapper image={singleNews.thumbnail} />
        <StyledInfoWrapper>
          <StyledEventHeading>{singleNews.title}</StyledEventHeading>
          <StyledDivider src={divider} alt="divider" />
          <StyledEventDesc>{singleNews.short_description}</StyledEventDesc>
        </StyledInfoWrapper>
      </StyledNewsWrapper>
    </StyledLink>
  );
});

export default NewsBox;
