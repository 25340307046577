import React from "react";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { view } from "@risingstack/react-easy-state";
import moment from "moment";

// import shareIcon from "../../assets/img/follow_icon.svg";

const StyledGradientOverlay = styled(Box)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 145, 212, 0.5) 0%,
    rgba(22, 30, 32, 0.5) 100%
  );
  mix-blend-mode: normal;
  opacity: 0.78;
`;

const StyledDarkOverlay = styled(Box)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #161e20;
  mix-blend-mode: normal;
  opacity: 0.3;
`;

const StyledLink = styled(Link)`
  all: unset;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

// const StyledShareIcon = styled.span`
//   all: unset;
//   cursor: pointer;

//   &:hover {
//     opacity: 0.8;
//   }
// `;

const StyledDateWrapper = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  height: 88px;
  width: 88px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0091d4;
`;

const StyledDateDay = styled.p`
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 36px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 3.21429px;
  margin: 0;
  margin-bottom: 3px;
`;

const StyledDateMonth = styled.p`
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.128571px;
  letter-spacing: 0.0714286px;
  margin: 0;
`;

const StyledNewsWrapper = styled(Box)`
  position: relative;
  background-color: #161e20;
  background: url(${(props) => props.image}) no-repeat center;
  background-size: cover;
  padding: 40px 46px 40px 46px;
  height: 600px;
  // min-height: 600px;
  // height: 100%;
`;

const StyledInnerWrapper = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const StyledHeadingWrapper = styled(Box)`
  padding-left: 132px;
  padding-right: 72px;

  @media (max-width: 599px) {
    padding-left: 0;
    padding-right: 0;
    padding-top: 88px;
  }
`;

const StyledDescWrapper = styled(Box)``;

const StyledNewsDesc = styled.p`
  font-family: "AzoSans Regular", "Arial", sans-serif;
  font-size: 21px;
  line-height: 32px;
  letter-spacing: 0.154412px;
  color: #ffffff;
`;

const StyledNewsHeading = styled.p`
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 36px;
  line-height: 48px;
  letter-spacing: 0.264706px;
  margin: 0;
  margin-bottom: 11px;
`;

const StyledBottomWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledInfoWrapper = styled(Box)``;

const StyledInfoTime = styled.p`
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.128571px;
  margin: 0;
  margin-bottom: 9px;

  span {
    color: #2690d7;
  }
`;

// const StyledButtonWrapper = styled(Box)`
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
// `;

const NewsFeaturedBox = view((props) => {
  const { singleNews } = props;

  const date = moment(singleNews.created_at).format("D");
  const month = moment(singleNews.created_at).format("MMM");
  const day = moment(singleNews.created_at).format("dddd");
  const time = moment(singleNews.created_at).format("h A");

  return (
    <StyledLink to={`/news/${singleNews.id}`}>
      <StyledNewsWrapper image={singleNews.thumbnail}>
        <StyledGradientOverlay />
        <StyledDarkOverlay />
        <StyledDateWrapper>
          <StyledDateDay>{date}</StyledDateDay>
          <StyledDateMonth>{month}</StyledDateMonth>
        </StyledDateWrapper>
        <StyledInnerWrapper>
          <StyledHeadingWrapper>
            <StyledNewsHeading>{singleNews.title}</StyledNewsHeading>
          </StyledHeadingWrapper>

          <StyledBottomWrapper>
            <Box>
              <StyledDescWrapper>
                <StyledNewsDesc>{singleNews.short_description}</StyledNewsDesc>
              </StyledDescWrapper>
              <StyledInfoWrapper>
                <StyledInfoTime>
                  <span>{day}</span> {time}
                </StyledInfoTime>
              </StyledInfoWrapper>
            </Box>
            {/* <StyledButtonWrapper>
            <StyledShareIcon>
              <img src={shareIcon} alt="share_icon" />
            </StyledShareIcon>
          </StyledButtonWrapper> */}
          </StyledBottomWrapper>
        </StyledInnerWrapper>
      </StyledNewsWrapper>
    </StyledLink>
  );
});

export default NewsFeaturedBox;
