import { store } from "@risingstack/react-easy-state";
import { Auth } from "aws-amplify";
import AWS from "aws-sdk";
import { AwsClient } from "aws4fetch";

import { API_ENDPOINT } from "../consts/globals";
import { PAYMENT_ENDPOINT } from "../consts/globals";

import axios from "axios";

const abortController = new AbortController();
const signal = abortController.signal;

let aws;

export const auth = store({
  authState: "signIn",
  user: null,
  creds: null,
  isLoggedIn: false,
  isLoading: true,
  errors: null,
  async getUser() {
    try {
      auth.isLoading = true;
      let user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      auth.user = user;
      await auth.getCredentials();
      await gdpr.getTerms();
      auth.isLoggedIn = true;
      auth.isLoading = false;
    } catch {
      auth.user = null;
      auth.isLoggedIn = false;
      auth.isLoading = false;
    }
  },
  async getCredentials() {
    const creds = await Auth.currentCredentials();
    auth.creds = creds;
    AWS.config.update({
      region: process.env.REACT_APP_AWS_USER_POOLS_REGION,
      accessKeyId: creds.accessKeyId,
      secretAccessKey: creds.secretAccessKey,
      sessionToken: creds.sessionToken,
    });

    aws = new AwsClient({
      accessKeyId: creds.accessKeyId,
      secretAccessKey: creds.secretAccessKey,
      sessionToken: creds.sessionToken,
    });
  },
  async updateUser(attributes) {
    auth.isLoading = true;

    let params = {
      AccessToken: auth.user.signInUserSession.accessToken.jwtToken,
      UserAttributes: [],
    };

    if (attributes.name) {
      params.UserAttributes.push({
        Name: "name",
        Value: attributes.name,
      });
    }
    if (attributes.street) {
      params.UserAttributes.push({
        Name: "custom:street",
        Value: attributes.street,
      });
    }
    if (attributes.zip) {
      params.UserAttributes.push({
        Name: "custom:zip",
        Value: attributes.zip,
      });
    }
    if (attributes.city) {
      params.UserAttributes.push({
        Name: "custom:city",
        Value: attributes.city,
      });
    }
    if (attributes.country) {
      params.UserAttributes.push({
        Name: "custom:country",
        Value: attributes.country,
      });
    }
    if (attributes.phone_number) {
      params.UserAttributes.push({
        Name: "phone_number",
        Value: attributes.phone_number,
      });
    }

    const cognito = new AWS.CognitoIdentityServiceProvider();
    cognito.updateUserAttributes(params, function (error, data) {
      if (error) {
        console.log(error);
        auth.errors = error;
        auth.isLoading = false;
      } else {
        auth.errors = null;
        auth.refreshToken();
        auth.getUser();
        auth.isLoading = false;
      }
    });
  },
  async refreshToken() {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession(
        currentSession.refreshToken,
        (err, session) => {
          // const { idToken, refreshToken, accessToken } = session;
        }
      );
    } catch (e) {
      console.log("Unable to refresh Token", e);
    }
  },
  async logout() {
    await Auth.signOut();
    auth.user = null;
    auth.isLoggedIn = false;
    player.playerLol = null;
    player.playerDota = null;
  },
});

export const location = store({
  prevLocation: null,
});

export const player = store({
  playerLol: null,
  playerDota: null,
  playersLol: null,
  playersDota: null,
  playerDetailsLol: null,
  playerDetailsDota: null,
  searchedPlayerLol: null,
  searchedPlayerDota: null,
  isLoading: true,
  errors: null,
  async getUserData() {
    player.isLoading = true;

    if (!aws) {
      await auth.getUser();
    }

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/user-data?include=userDataLol,userDataDota2`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            // "X-User-Sub": "25e9454b-0a1a-44ee-98a6-ac789b25b4a7",
            "X-User-Sub": auth.user.attributes.sub,
          },
        }
      );

      const responseJson = await response.json();

      // if (!response.ok) {
      //   throw Error(response.statusText);
      // }

      if (responseJson.SummonerData.length > 0) {
        player.playerLol = responseJson.SummonerData[0];
      }

      if (responseJson.Dota2Data.length > 0) {
        player.playerDota = responseJson.Dota2Data[0];
      }

      player.isLoading = false;
      return responseJson;
    } catch (error) {
      // console.log(error);
      player.isLoading = false;
    }
  },
  async getPlayersLol() {
    if (!aws) {
      await auth.getUser();
    }

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/summoners?include=userStatsLol`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
        }
      );

      const responseJson = await response.json();

      if (!response.ok) {
        throw Error(response.statusText);
      }

      player.playersLol = responseJson.data;
      return responseJson.data;
    } catch (error) {
      console.log(error);
    }
  },
  async getPlayerLol(id) {
    if (!aws) {
      await auth.getUser();
    }

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/summoners/${id}?include=userStatsLol`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
        }
      );

      const responseJson = await response.json();

      if (!response.ok) {
        throw Error(response.statusText);
      }

      player.playerDetailsLol = responseJson;
      return responseJson.data;
    } catch (error) {
      console.log(error);
    }
  },
  async addPlayerLol(values) {
    player.isLoading = true;

    const params = {
      ...values,
    };

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/summoners`, {
        signal: signal,
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          "X-User-Sub": auth.user.attributes.sub,
        },
        body: JSON.stringify(params),
      });

      const httpCode = await response.status;
      const responseJson = await response.json();

      if (httpCode !== 200) {
        player.errors = responseJson.errors;
      } else {
        player.errors = null;
      }

      if (!response.ok) {
        throw Error(response.statusText);
      }

      player.getUserData();
      player.isLoading = false;
      return responseJson.data;
    } catch (error) {
      console.log(error);
      player.isLoading = false;
    }
  },
  async updatePlayerLol(id, values) {
    player.isLoading = true;

    const params = {
      preferred_position: values.preferred_position,
    };

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/summoners/${id}`,
        {
          signal: signal,
          method: "PATCH",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
          body: JSON.stringify(params),
        }
      );

      const responseJson = await response.json();

      if (!response.ok) {
        throw Error(response.statusText);
      }

      player.getUserData();
      player.isLoading = false;
      return responseJson.data;
    } catch (error) {
      console.log(error);
      player.isLoading = false;
    }
  },
  async searchPlayerLol(input) {
    if (!aws) {
      await auth.getUser();
    }

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/summoners?include=userStatsLol&filter-summoner_name=${input}`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
        }
      );

      const responseJson = await response.json();

      if (!response.ok) {
        throw Error(response.statusText);
      }

      player.searchedPlayerLol = responseJson.data;
      return responseJson.data;
    } catch (error) {
      console.log(error);
    }
  },
  async getPlayersDota() {
    if (!aws) {
      await auth.getUser();
    }

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/dota2-acc`, {
        signal: signal,
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          "X-User-Sub": auth.user.attributes.sub,
        },
      });

      const responseJson = await response.json();

      if (!response.ok) {
        throw Error(response.statusText);
      }

      player.playersDota = responseJson.data;
      return responseJson.data;
    } catch (error) {
      console.log(error);
    }
  },
  async getPlayerDota(id) {
    if (!aws) {
      await auth.getUser();
    }

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/dota2-acc/${id}`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
        }
      );

      const responseJson = await response.json();

      if (!response.ok) {
        throw Error(response.statusText);
      }

      player.playerDetailsDota = responseJson;
      return responseJson.data;
    } catch (error) {
      console.log(error);
    }
  },
  async addPlayerDota(values) {
    player.isLoading = true;

    const params = {
      ...values,
      dota2_id: parseInt(values.dota2_id),
    };

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/dota2-acc`, {
        signal: signal,
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          "X-User-Sub": auth.user.attributes.sub,
        },
        body: JSON.stringify(params),
      });

      const httpCode = await response.status;
      const responseJson = await response.json();

      if (httpCode !== 200) {
        player.errors = responseJson.errors;
      } else {
        player.errors = null;
      }

      if (!response.ok) {
        throw Error(response.statusText);
      }

      player.getUserData();
      player.isLoading = false;
      return responseJson.data;
    } catch (error) {
      console.log(error);
      player.isLoading = false;
    }
  },
  async updatePlayerDota(id, values) {
    player.isLoading = true;

    const params = {
      preferred_position: values.preferred_position,
    };

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/dota2-acc/${id}`,
        {
          signal: signal,
          method: "PATCH",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
          body: JSON.stringify(params),
        }
      );

      const responseJson = await response.json();

      if (!response.ok) {
        throw Error(response.statusText);
      }

      player.getUserData();
      player.isLoading = false;
      return responseJson.data;
    } catch (error) {
      console.log(error);
      player.isLoading = false;
    }
  },
  async searchPlayerDota(input) {
    if (!aws) {
      await auth.getUser();
    }

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/dota2-acc?filter-dota2_name=${input}`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
        }
      );

      const responseJson = await response.json();

      if (!response.ok) {
        throw Error(response.statusText);
      }

      player.searchedPlayerDota = responseJson.data;
      return responseJson.data;
    } catch (error) {
      console.log(error);
    }
  },
});

export const news = store({
  news: null,
  singleNews: null,
  async getNews() {
    try {
      const response = await axios.get(`${API_ENDPOINT}/system/news`);

      if (response.status !== 200) {
        throw Error(response.statusText);
      }

      news.news = response.data.data;
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  },
  async getSingleNews(id) {
    try {
      const response = await axios.get(`${API_ENDPOINT}/system/news/${id}`);

      if (response.status !== 200) {
        throw Error(response.statusText);
      }

      news.singleNews = response.data;
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
});

export const events = store({
  events: null,
  singleEvent: null,
  async getEvents() {
    // if (!aws) {
    //   await auth.getUser();
    // }

    // try {
    //   const response = await aws.fetch(
    //     `${API_ENDPOINT}/system/events?include=venue`,
    //     {
    //       signal: signal,
    //       method: "GET",
    //       headers: {
    //         "Content-type": "application/json",
    //         Accept: "application/json",
    //         "X-User-Sub": auth.user.attributes.sub,
    //       },
    //     }
    //   );

    //   const responseJson = await response.json();

    //   if (!response.ok) {
    //     throw Error(response.statusText);
    //   }

    //   events.events = responseJson.data;
    //   return responseJson.data;
    // } catch (error) {
    //   console.log(error);
    // }

    try {
      const response = await axios.get(
        `${API_ENDPOINT}/system/events?include=venue&filter-type=event`
      );

      if (response.status !== 200) {
        throw Error(response.statusText);
      }

      events.events = response.data.data;
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  },
  async getSingleEvent(id) {
    // if (!aws) {
    //   await auth.getUser();
    // }

    // try {
    //   const response = await aws.fetch(
    //     `${API_ENDPOINT}/system/events/${id}?include=venue`,
    //     {
    //       signal: signal,
    //       method: "GET",
    //       headers: {
    //         "Content-type": "application/json",
    //         Accept: "application/json",
    //         "X-User-Sub": auth.user.attributes.sub,
    //       },
    //     }
    //   );

    //   const responseJson = await response.json();

    //   if (!response.ok) {
    //     throw Error(response.statusText);
    //   }

    //   events.singleEvent = responseJson;
    //   return responseJson;
    // } catch (error) {
    //   console.log(error);
    // }
    try {
      const response = await axios.get(
        `${API_ENDPOINT}/system/events/${id}?include=venue`
      );

      if (response.status !== 200) {
        throw Error(response.statusText);
      }

      events.singleEvent = response.data;
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
});

export const trainings = store({
  trainings: null,
  singleTraining: null,
  async getTrainings() {
    // try {
    //   const response = await axios.get(
    //     `${API_ENDPOINT}/system/events?include=venue&filter-type=training`
    //   );

    //   if (response.status !== 200) {
    //     throw Error(response.statusText);
    //   }

    //   trainings.trainings = response.data.data;
    //   return response.data.data;
    // } catch (error) {
    //   console.log(error);
    // }
    try {
      const response = await axios.get(
        `${API_ENDPOINT}/system/events?include=venue&filter-type=training`
      );

      if (response.status !== 200) {
        throw Error(response.statusText);
      }

      trainings.trainings = response.data.data;
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  },
  async getSingleTraining(id) {
    // if (!aws) {
    //   await auth.getUser();
    // }

    // try {
    //   const response = await aws.fetch(
    //     `${API_ENDPOINT}/system/events/${id}?include=venue`,
    //     {
    //       signal: signal,
    //       method: "GET",
    //       headers: {
    //         "Content-type": "application/json",
    //         Accept: "application/json",
    //         "X-User-Sub": auth.user.attributes.sub,
    //       },
    //     }
    //   );

    //   const responseJson = await response.json();

    //   if (!response.ok) {
    //     throw Error(response.statusText);
    //   }

    //   trainings.singleTraining = responseJson;
    //   return responseJson;
    // } catch (error) {
    //   console.log(error);
    // }
    try {
      const response = await axios.get(
        `${API_ENDPOINT}/system/events/${id}?include=venue`
      );

      if (response.status !== 200) {
        throw Error(response.statusText);
      }

      trainings.singleTraining = response.data;
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
});

export const bookings = store({
  bookings: null,
  singleBooking: null,
  isLoading: false,
  paymentMessage: null,
  cancelMessage: null,
  async getBookings() {
    bookings.isLoading = true;

    if (!aws) {
      await auth.getUser();
    }

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/events?include=venue`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
        }
      );

      const responseJson = await response.json();

      if (!response.ok) {
        throw Error(response.statusText);
      }

      bookings.bookings = responseJson.data;
      bookings.isLoading = false;
      return responseJson.data;
    } catch (error) {
      console.log(error);
      bookings.isLoading = false;
    }
  },
  // async getSingleBooking(id) {
  //   if (!aws) {
  //     await auth.getUser();
  //   }

  //   try {
  //     const response = await aws.fetch(
  //       `${API_ENDPOINT}/system/events/${id}?include=venue`,
  //       {
  //         signal: signal,
  //         method: "GET",
  //         headers: {
  //           "Content-type": "application/json",
  //           Accept: "application/json",
  //           "X-User-Sub": auth.user.attributes.sub,
  //         },
  //       }
  //     );

  //     const responseJson = await response.json();

  //     if (!response.ok) {
  //       throw Error(response.statusText);
  //     }

  //     events.singleEvent = responseJson;
  //     return responseJson;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // },
  async getUserBooking(eventId) {
    bookings.isLoading = true;

    if (!aws) {
      await auth.getUser();
    }

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/bookings/?filter-user_id=${auth.user.attributes.sub}&filter-event_id=${eventId}`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
        }
      );

      const responseJson = await response.json();

      if (!response.ok) {
        throw Error(response.statusText);
      }

      bookings.singleBooking = responseJson.data;
      bookings.isLoading = false;
      return responseJson;
    } catch (error) {
      console.log(error);
      bookings.isLoading = false;
    }
  },
  async createUserBooking(eventId) {
    bookings.isLoading = true;

    if (!aws) {
      await auth.getUser();
    }

    const params = {
      event_id: eventId,
    };

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/bookings`, {
        signal: signal,
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          "X-User-Sub": auth.user.attributes.sub,
        },
        body: JSON.stringify(params),
      });

      const responseJson = await response.json();

      if (!response.ok) {
        throw Error(response.statusText);
      }

      await bookings.getUserBooking(eventId);
      return responseJson;
    } catch (error) {
      console.log(error);
      bookings.isLoading = false;
    }
  },
  async cancelUserBooking(eventId, bookingId) {
    bookings.isLoading = true;

    if (!aws) {
      await auth.getUser();
    }

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/bookings/${bookingId}`,
        {
          signal: signal,
          method: "DELETE",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
        }
      );

      const responseJson = await response.json();

      if (!response.ok) {
        throw Error(response.statusText);
      }

      if (responseJson.errors) {
        bookings.cancelMessage = responseJson.message;
      } else {
        bookings.cancelMessage = responseJson;
      }

      await bookings.getUserBooking(eventId);
      return responseJson;
    } catch (error) {
      console.log(error);
      bookings.isLoading = false;
    }
  },
  async createPaymentMethod(stripe, cardElement) {
    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });
    if (error) {
      console.log("[error]", error);
      return;
    } else {
      bookings.payForInvoice(
        paymentMethod.id,
        bookings.singleBooking[0].invoice_id
      );
    }
  },
  async payForInvoice(paymentMethod, invoiceId) {
    bookings.isLoading = true;

    if (!aws) {
      await auth.getUser();
    }

    const params = {
      payment_method: paymentMethod,
      invoice_id: invoiceId,
    };

    try {
      const response = await aws.fetch(
        `${PAYMENT_ENDPOINT}/stripe/pay-for-invoice`,
        {
          signal: signal,
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-System": "esports",
            "X-User-Sub": auth.user.attributes.sub,
          },
          body: JSON.stringify(params),
        }
      );

      const responseJson = await response.json();

      if (responseJson.errors) {
        bookings.paymentMessage = responseJson.message;
      } else {
        bookings.paymentMessage = responseJson;
      }

      bookings.isLoading = false;
    } catch (error) {
      console.log(error);
      bookings.isLoading = false;
    }
  },
});

export const invoices = store({
  invoices: null,
  async getInvoices() {
    if (!aws) {
      await auth.getUser();
    }

    try {
      const response = await aws.fetch(`${PAYMENT_ENDPOINT}/user-invoices`, {
        signal: signal,
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          "X-User-Sub": auth.user.attributes.sub,
        },
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      invoices.invoices = responseJson.data;
    } catch (error) {
      console.log(error);
    }
  },
});

export const gdpr = store({
  terms: null,
  publicTerms: null,
  loading: false,
  async getPublicTerms() {
    try {
      const response = await axios.get(`${API_ENDPOINT}/system/legal-terms`);

      gdpr.publicTerms = response.data.data;
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  },
  async getTerms() {
    gdpr.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/legal-terms/check-user`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      gdpr.terms = responseJson.data;
      gdpr.loading = false;
    } catch (error) {
      gdpr.loading = false;
      console.log(error);
    }
  },
  async acceptTerms(values) {
    gdpr.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    let terms = {
      accepted: [],
      rejected: [],
    };

    Object.keys(values).map((key) => {
      if (values[key]) {
        terms.accepted.push(key);
      } else {
        terms.rejected.push(key);
      }

      return terms;
    });

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/legal-terms/update-accepted`,
        {
          signal: signal,
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
          body: JSON.stringify(terms),
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      await response.json();

      await gdpr.getTerms();
      // gdpr.loading = false;
    } catch (error) {
      // gdpr.loading = false;
      console.log(error);
    }
  },
});

export const contact = store({
  loading: true,
  errors: null,
  async sendMessage(values) {
    contact.loading = true;
    try {
      const response = await axios.post(`${API_ENDPOINT}/system/contact-us`, {
        email: values.email,
        full_name: values.full_name,
        subject: values.subject,
        message: values.message,
      });

      contact.loading = false;
      return response;
    } catch (error) {
      console.log(error);
      contact.errors = error;
      contact.loading = false;
    }
  },
});
