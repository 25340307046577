import React, { Fragment, forwardRef } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import MaterialTable from "material-table";
// import { Link } from "react-router-dom";
import { view } from "@risingstack/react-easy-state";

import { invoices } from "../../stores/BaseStore";

import AddBox from "@material-ui/icons/AddBox";
// import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import UnfoldMore from "@material-ui/icons/UnfoldMore";

// import eventThumb from "../../assets/img/event_thumb.png";
import { useEffect } from "react";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <UnfoldMore {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const StyledTable = styled(Box)`
  .MuiPaper-root {
    background-color: #161e20;
    box-shadow: none;
  }

  .MuiTableCell-head {
    background-color: #161e20;
    font-family: "Polaris", "Arial", sans-serif;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.0857143px;
    padding: 20px 40px 20px 40px;
  }

  .MuiTableCell-body {
    font-family: "AzoSans Regular", "Arial", sans-serif;
    font-size: 16px !important;
    line-height: 24px;
    letter-spacing: 0.5px;
    padding: 12px 12px 12px 40px;
  }

  tbody {
    tr {
      td {
        border-bottom: 1px solid #080023;
      }

      &:not(:last-child) {
        td {
          border-bottom: none;
        }
      }
    }
  }

  .MuiTableRow-footer {
    td {
      border-bottom: none;
    }
  }

  .MuiToolbar-root {
    padding: 46px 40px 46px 40px;
  }

  .MuiTypography-caption {
    font-family: "AzoSans Bold", "Arial", sans-serif;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    letter-spacing: 0.25px;
    color: #ffffff;
  }

  .MuiTablePagination-spacer {
    flex: 0;
  }

  .MuiTableSortLabel-icon {
    opacity: 1;
  }
`;

// const StyledLink = styled(Link)`
//   all: unset;
//   cursor: pointer;
// `;

// const StyledImageWrapper = styled.div`
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
// `;

const ProfileInvoices = view(() => {
  useEffect(() => {
    if (!invoices.invoices) {
      invoices.getInvoices();
    }
  });

  return !invoices.invoices ? null : (
    <Fragment>
      <Grid
        container
        justify={"space-between"}
        alignItems={"center"}
        style={{ marginBottom: "32px" }}
      >
        <Box>
          <h4 style={{ margin: 0 }}>Invoices</h4>
        </Box>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <StyledTable mb={12}>
            <MaterialTable
              columns={[
                {
                  title: "#",
                  field: "number",
                  headerStyle: {
                    color: "#0091D4",
                  },
                  width: 50,
                },
                {
                  title: "offer number",
                  field: "offer_number",
                  cellStyle: {
                    fontFamily: '"AzoSans Bold", "Arial", sans-serif',
                  },
                  /* render: (rowData) => (
                    <StyledLink to={`/events/${rowData.name}`}>
                      <StyledImageWrapper>
                        <img
                          src={rowData.img}
                          alt="avatar"
                          style={{
                            height: "56px",
                            width: "56px",
                            marginRight: "28px",
                          }}
                        />
                        <span>{rowData.name}</span>
                      </StyledImageWrapper>
                    </StyledLink>
                  ), */
                },
                {
                  title: "invoice number",
                  field: "invoice_number",
                  cellStyle: {
                    fontFamily: '"AzoSans Bold", "Arial", sans-serif',
                  },
                },
                {
                  title: "date",
                  field: "issued_at",
                  cellStyle: { color: "#A7ACB3", textTransform: "uppercase" },
                },
                {
                  title: "status",
                  field: "status",
                  cellStyle: { color: "#A7ACB3", textTransform: "uppercase" },
                },
              ]}
              data={invoices.invoices.map((invoice, index) => {
                let modified = { ...invoice, number: index };
                return modified;
              })}
              title="invoices"
              icons={tableIcons}
              options={{
                search: false,
                toolbar: false,
                pageSize: 10,
                pageSizeOptions: [1],
                draggable: false,
                rowStyle: (rowData) => ({
                  backgroundColor:
                    rowData.tableData.id % 2 ? "#161E20" : "#273032",
                  border: "none",
                }),
              }}
            />
          </StyledTable>
        </Grid>
      </Grid>
    </Fragment>
  );
});

export default ProfileInvoices;
