import React, { Fragment } from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import { createGlobalStyle } from "styled-components";
import { Switch, Route } from "react-router-dom";
import "@aws-amplify/ui/dist/style.css";
import AzoSansRegular from "../assets/fonts/AzoSans/AzoSansRegular.woff";
import AzoSansMedium from "../assets/fonts/AzoSans/AzoSansMedium.woff";
import AzoSansBold from "../assets/fonts/AzoSans/AzoSansBold.woff";
import AzoSansBlack from "../assets/fonts/AzoSans/AzoSansBlack.woff";
import Polaris from "../assets/fonts/Polaris/Polaris.otf";
import { useLocation } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { view } from "@risingstack/react-easy-state";

import { auth, news, gdpr } from "../stores/BaseStore";

import homeBackground from "../assets/img/home_bg.png";
import noBackground from "../assets/img/profile_bg.svg";
import playersBackground from "../assets/img/players_bg.png";

import Navbar from "../components/shared/Navbar";
import Home from "../components/routes/Home";
import Login from "../components/routes/Login";
import AcceptTerms from "../components/routes/AcceptTerms";
import ProtectedRoute from "../components/protected/ProtectedRoute";
import Profile from "../components/routes/Profile";
import Players from "../components/routes/Players";
import Player from "../components/routes/Player";
import Events from "../components/routes/Events";
import Trainings from "../components/routes/Trainings";
import News from "../components/routes/News";
import SingleNews from "../components/routes/SingleNews";
import SingleEvent from "../components/routes/SingleEvent";
import SingleTraining from "../components/routes/SingleTraining";
import SearchResults from "./routes/SearchResults";
import TermsAndConditions from "./gdpr/TermsAndConditions";
import Privacy from "./gdpr/Privacy";
import Impressum from "./gdpr/Impressum";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'AzoSans Regular';
    src: local('AzoSans Regular'), local('AzoSansRegular'),
    url(${AzoSansRegular}) format('woff')
  }
  @font-face {
    font-family: 'AzoSans Medium';
    src: local('AzoSans Medium'), local('AzoSansMedium'),
    url(${AzoSansMedium}) format('woff')
  }
  @font-face {
    font-family: 'AzoSans Bold';
    src: local('AzoSans Bold'), local('AzoSansBold'),
    url(${AzoSansBold}) format('woff')
  }
  @font-face {
    font-family: 'AzoSans Black';
    src: local('AzoSans Black'), local('AzoSansBlack'),
    url(${AzoSansBlack}) format('woff')
  }
  @font-face {
    font-family: 'Polaris';
    src: local('Polaris'), local('Polaris'),
    url(${Polaris}) format('opentype')
  }

  body {
    font-family: 'AzoSans Regular', 'Arial', sans-serif;
  }

  a {
    outline: 0;
  }

  h2 {
    color: #ffffff;
    font-family: 'AzoSans Bold', 'Arial', sans-serif;
    font-size: 60px;
    line-height: 80px;
    letter-spacing: 0.441176px;
  }

  h3 {
    font-family: 'AzoSans Bold', 'Arial', sans-serif;
    font-size: 48px;
    line-height: 48px;
    letter-spacing: 0.352941px;
  }

  h4 {
    font-family: 'Polaris', 'Arial', sans-serif;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: 0.264706px;
  }

  h5 {
    font-family: 'Polaris', 'Arial', sans-serif;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.176471px;
  }

  h6 {
    font-family: 'Polaris', 'Arial', sans-serif;
    font-size: 21px;
    line-height: 32px;
    letter-spacing: 0.154412px;
  }

  a {
    color: #fcf032;

    &:visited {
      color: unset;
    }
  }

  button {
    font-family: 'Polaris', 'Arial', sans-serif;
  }
`;

// const StyledDarkOverlay = styled(Box)`
//   position: absolute;
//   height: 100%;
//   width: 100%;
//   top: 0;
//   left: 0;
//   background: #161e20;
//   mix-blend-mode: normal;
//   opacity: 0.5;
//   // opacity: 0.3;
//   z-index: 2;
// `;

const StyledGradientOverlay = styled(Box)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: radial-gradient(
    95.65% 54.86% at 50% 44.49%,
    rgba(0, 145, 212, 0.5) 0%,
    #161e20 100%
  );
  z-index: 1;
`;

const StyledNoBackground = styled(Box)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: url(${noBackground}) no-repeat;
  background-size: cover;
  z-index: 0;
`;

const StyledHomeBackground = styled(Box)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: url(${homeBackground}) no-repeat;
  background-size: cover;
  z-index: 0;
`;

const StyledPlayersBackground = styled(Box)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: url(${playersBackground}) no-repeat;
  background-size: cover;
  z-index: 0;
`;

const StyledNewsBackground = styled(Box)`
  position: absolute;
  height: 700px;
  width: 100%;
  top: 0;
  left: 0;
  background: url(${(props) => props.image}) no-repeat;
  background-size: cover;
  z-index: 0;
`;

const StyledNewsBackgroundOverlay = styled(Box)`
  background: #161e20;
  position: absolute;
  height: 20%;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 0;
`;

const StyledContainer = styled(Container)`
  position: relative;
  background: #161e20;
  min-height: 100vh;
  height: 100%;
  width: 100%;
`;

const darkTheme = createMuiTheme({
  palette: {
    type: "dark",
  },
});

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

const Wrapper = view(() => {
  const location = useLocation();

  return (
    <Fragment>
      <ThemeProvider theme={darkTheme}>
        <Elements stripe={stripePromise}>
          <CssBaseline />
          <GlobalStyle />
          <StyledContainer maxWidth={false} disableGutters>
            {location.pathname === "/" ? (
              <StyledHomeBackground />
            ) : location.pathname === "/profile" ? (
              <StyledNoBackground />
            ) : location.pathname === "/players" ? (
              <StyledPlayersBackground />
            ) : location.pathname.includes("/players/") ? (
              <StyledNoBackground />
            ) : location.pathname.includes("/events") ? (
              <StyledNoBackground />
            ) : location.pathname.includes("/trainings") ? (
              <StyledNoBackground />
            ) : location.pathname === "/news" ? (
              <StyledNoBackground />
            ) : location.pathname.includes("/news/") ? (
              <Fragment>
                <StyledNewsBackground
                  image={news.singleNews ? news.singleNews.thumbnail : null}
                >
                  <StyledNewsBackgroundOverlay />
                </StyledNewsBackground>

                <StyledNoBackground />
              </Fragment>
            ) : location.pathname.includes("/search-results") ? (
              <StyledNoBackground />
            ) : location.pathname === "/terms-and-conditions" ? (
              <StyledNoBackground />
            ) : location.pathname === "/privacy" ? (
              <StyledNoBackground />
            ) : location.pathname === "/impressum" ? (
              <StyledNoBackground />
            ) : location.pathname === "/accept-terms" ? (
              <StyledNoBackground />
            ) : null}
            {location.pathname === "/login" ? <StyledGradientOverlay /> : null}
            <Navbar />
            <Switch>
              {/* <ProtectedRoute path="/" exact auth={auth} gdpr={gdpr}>
                <Home />
              </ProtectedRoute> */}
              <Route path="/" exact component={Home} props={auth} />
              <Route path="/login" exact component={Login} props={auth} />
              <ProtectedRoute
                path="/accept-terms"
                exact
                auth={auth}
                gdpr={gdpr}
              >
                <AcceptTerms />
              </ProtectedRoute>
              <ProtectedRoute path="/players" exact auth={auth} gdpr={gdpr}>
                <Players />
              </ProtectedRoute>
              <ProtectedRoute
                path="/players/:game/:id"
                exact
                auth={auth}
                gdpr={gdpr}
              >
                <Player />
              </ProtectedRoute>
              {/* <ProtectedRoute path="/events" exact auth={auth} gdpr={gdpr}>
                <Events />
              </ProtectedRoute> */}
              {/*<ProtectedRoute path="/news" exact props={auth}>*/}
              {/*  <News />*/}
              {/*</ProtectedRoute>*/}
              {/*<ProtectedRoute path="/news/:id" exact props={auth}>*/}
              {/*  <SingleNews />*/}
              {/*</ProtectedRoute>*/}
              {/* <ProtectedRoute path="/events/:id" exact auth={auth} gdpr={gdpr}>
                <SingleEvent />
              </ProtectedRoute> */}
              <ProtectedRoute
                path="/trainings/:id"
                exact
                auth={auth}
                gdpr={gdpr}
              >
                <SingleTraining />
              </ProtectedRoute>
              <ProtectedRoute
                path="/search-results/:game/:name"
                exact
                auth={auth}
                gdpr={gdpr}
              >
                <SearchResults />
              </ProtectedRoute>
              {/* <Route path="/" exact component={Home} />
            <Route path="/login" exact component={Login} props={auth} />
            <Route path="/players" exact component={Players} props={auth} />
            <Route
              path="/players/:game/:id"
              exact
              component={Player}
              props={auth}
            />
            <Route path="/events" exact component={Events} props={auth} /> */}
              <Route path="/news" exact component={News} props={auth} />
              <Route path="/events" exact component={Events} props={auth} />
              <Route
                path="/events/:id"
                exact
                component={SingleEvent}
                auth={auth}
              />
              <Route
                path="/trainings"
                exact
                component={Trainings}
                props={auth}
              />
              <Route
                path="/trainings/:id"
                exact
                component={SingleTraining}
                auth={auth}
              />
              <Route
                path="/news/:id"
                exact
                component={SingleNews}
                props={auth}
              />
              <ProtectedRoute path="/profile" exact auth={auth} gdpr={gdpr}>
                <Profile />
              </ProtectedRoute>
              <Route path="/terms-and-conditions" exact>
                <TermsAndConditions />
              </Route>
              <Route path="/privacy" exact>
                <Privacy />
              </Route>
              <Route path="/impressum" exact>
                <Impressum />
              </Route>
              <Route path="/riot.txt" />
            </Switch>
          </StyledContainer>
        </Elements>
      </ThemeProvider>
    </Fragment>
  );
});

export default Wrapper;
