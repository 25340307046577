import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import { view, autoEffect } from "@risingstack/react-easy-state";
import { useHistory } from "react-router-dom";

import signInBackground from "../../assets/img/sign_in_background.png";
import signUpBackground from "../../assets/img/sign_up_background.png";
import confirmSignUpBackground from "../../assets/img/confirm_sign_up_background.png";

import { auth, location } from "../../stores/BaseStore";
import Auth from "../Auth";
import GdprFooter from "../shared/GdprFooter";

const StyledGridContainer = styled(Grid)`
  min-height: 100vh;
  position: relative;
`;

const StyledSignInImage = styled(Box)`
  height: 100%;
  width: 100%;
  background: url(${signInBackground}) no-repeat center center;
  background-size: cover;
`;

const StyledSignUpImage = styled(Box)`
  height: 100%;
  width: 100%;
  background: url(${signUpBackground}) no-repeat center center;
  background-size: cover;
`;

const StyledConfirmSignUpImage = styled(Box)`
  height: 100%;
  width: 100%;
  background: url(${confirmSignUpBackground}) no-repeat center center;
  background-size: cover;
`;

const Login = view(() => {
  let history = useHistory();

  autoEffect(() => {
    if (auth.isLoggedIn) {
      if (location.prevLocation) {
        history.push(location.prevLocation);
      } else {
        history.push("/");
      }
    }
  });

  return (
    <StyledGridContainer container>
      <Grid item xs={12} md={6}>
        {auth.authState === "signIn" || auth.authState === "signedUp" ? (
          <StyledSignInImage />
        ) : auth.authState === "signUp" ||
          auth.authState === "confirmSignUp" ? (
          <StyledSignUpImage />
        ) : auth.authState === "forgotPassword" ? (
          <StyledConfirmSignUpImage />
        ) : null}
      </Grid>
      <Grid item xs={12} md={6}>
        <Auth />
        <GdprFooter />
      </Grid>
    </StyledGridContainer>
  );
});

export default Login;
