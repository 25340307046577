import React, { useState, useEffect } from "react";
import AsyncSelect from "react-select/async";
import styled from "styled-components";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import { view } from "@risingstack/react-easy-state";

import { player } from "../../stores/BaseStore";

const StyledSearchWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: nowrap;

  @media (max-width: 959px) {
    justify-content: flex-start;
    margin-bottom: 48px;
  }
`;

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: "450px",
    marginRight: "24px",
    "@media (max-width: 959px)": {
      width: "100%",
    },
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: "transparent",
    borderRadius: "none",
    borderWidth: "0 !important",
    boxShadow: "none",
    borderBottom: "1px solid #ffffff !important",
    outline: "none",
  }),
  dropdownIndicator: () => ({
    display: "none",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#0091d4",
    fontSize: "16px",
  }),
  input: (provided) => ({
    ...provided,
    color: "#0091d4",
    fontSize: "16px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "17px",
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#273032",
    borderRadius: 0,
    top: "55px",
  }),
  option: (provided) => ({
    ...provided,
    backgroundColor: "transparent",
    padding: "17px",

    "&:hover": {
      color: "#0091d4",
      cursor: "pointer",
    },
  }),
};

const StyledButtonSecondary = styled.button`
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid #fafafa;
  border-radius: 0;
  padding: 20px;
  cursor: pointer;
  letter-spacing: 1.42857px;
  outline: 0;

  &:hover {
    opacity: 0.8;
  }

  width: 200px;

  &.active {
    background-color: #0091d4;
    border: 1px solid #0091d4;
  }
`;

const SearchDota = view(() => {
  const [value, setValue] = useState("");
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const history = useHistory();
  let allowCall = true;

  let results = (inputValue, data) =>
    data.filter((key) =>
      key.dota2_name.toLowerCase().includes(inputValue.toLowerCase())
    );

  const handleInputChange = (input, params) => {
    if (params.action === "input-change") {
      setValue({
        inputValue: input,
      });
    }
    // setValue({
    //   inputValue: input,
    // });
    return { value };
  };

  const handleChange = (selectedOption) => {
    setSelectedPlayer(selectedOption);
  };

  const handleGetResults = (inputValue) =>
    inputValue.length > 2 &&
    allowCall &&
    player
      .searchPlayerDota(inputValue)
      .then((response) => {
        return results(inputValue, response);
      })
      .catch((data) => {
        console.log(data);
      });

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(handleGetResults(inputValue));
      }, 1000);
    });

  let counter = setTimeout(() => {
    allowCall = true;
  }, 1000);

  const handleKeyDown = () => {
    allowCall = false;
    clearTimeout(counter);
    return counter;
  };

  const submitForm = () => {
    if (value.inputValue) {
      history.push(`/search-results/dota/${value.inputValue}`);
    }
  };

  useEffect(() => {
    if (selectedPlayer) {
      history.push(`/players/dota/${selectedPlayer.id}`);
    }
    // eslint-disable-next-line
  }, [selectedPlayer]);

  return (
    <form>
      <StyledSearchWrapper>
        <AsyncSelect
          styles={customStyles}
          value={selectedPlayer}
          loadOptions={promiseOptions}
          defaultOptions
          onInputChange={handleInputChange}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder="DotA name"
          noOptionsMessage={() => "No player with that name exists."}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => {
            return option.dota2_name;
          }}
        />
        <StyledButtonSecondary
          type="button"
          variant="contained"
          color="primary"
          onClick={submitForm}
          disabled={!value.inputValue}
        >
          Search
        </StyledButtonSecondary>
      </StyledSearchWrapper>
    </form>
  );
});

export default SearchDota;
