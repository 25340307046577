import React, { Fragment, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import CheckIcon from "@material-ui/icons/Check";
import styled from "styled-components";
import { view, autoEffect } from "@risingstack/react-easy-state";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
// import GoogleMapReact from "google-map-react";

// import followIcon from "../../assets/img/follow_icon.svg";

import { auth, events, bookings } from "../../stores/BaseStore";
import Loader from "../shared/Loader";
import Footer from "../shared/Footer";
import HeroNews from "../shared/HeroNews";
// import Marker from "../events/Marker";
import PaymentForm from "../payment/PaymentForm";
import CancelPayment from "../payment/CancelPayment";

// const StyledGradientOverlay = styled(Box)`
//   position: absolute;
//   height: 100%;
//   width: 100%;
//   top: 0;
//   left: 0;
//   background: linear-gradient(
//     180deg,
//     rgba(0, 145, 212, 0.5) 0%,
//     rgba(22, 30, 32, 0.5) 100%
//   );
//   mix-blend-mode: normal;
//   opacity: 0.78;
// `;

// const StyledDarkOverlay = styled(Box)`
//   position: absolute;
//   height: 100%;
//   width: 100%;
//   top: 0;
//   left: 0;
//   background: #161e20;
//   mix-blend-mode: normal;
//   opacity: 0.3;
// `;

const StyledContainer = styled(Container)`
  position: relative;
  z-index: 2;
  padding-top: 230px;
`;

const StyledEventWrapper = styled(Box)`
  position: relative;
  // background-color: #273032;
  padding: 0 0 0 136px;

  @media (max-width: 959px) {
    padding: 136px 0 0 0;
  }
`;

const StyledTopWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 31px;
`;

const StyledTopInner = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 959px) {
    width: 100%;
    justify-content: flex-start;
    margin-top: 24px;
  }
`;

// const StyledShareIcon = styled(Box)`
//   cursor: pointer;

//   &:hover {
//     opacity: 0.8;
//   }
// `;

const StyledDateWrapper = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  height: 88px;
  width: 88px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0091d4;
`;

const StyledDateDay = styled.p`
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 36px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 3.21429px;
  margin: 0;
  margin-bottom: 3px;
`;

const StyledDateMonth = styled.p`
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.128571px;
  letter-spacing: 0.0714286px;
  margin: 0;
`;

const StyledEventTime = styled.p`
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.128571px;
  color: #2690d7;
  margin: 0;

  span {
    color: #ffffff;
  }
`;

const StyledEventTitle = styled.p`
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 0.264706px;
  margin: 0;
  margin-bottom: 39px;
`;

const StyledBackButton = styled(Link)`
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 16px;
  line-height: 12px;
  letter-spacing: 1.42857px;
  color: #ffffff;
  margin-bottom: 42px;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  svg {
    margin-right: 26px;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const StyledButtonPrimary = styled.button`
  width: 100%;
  color: #ffffff;
  font-size: 24px;
  background-color: #0091d4;
  border: 1px solid #0091d4;
  border-radius: 0;
  padding: 20px;
  cursor: pointer;
  letter-spacing: 1.42857px;
  // margin-right: 48px;

  &:hover {
    opacity: 0.8;
  }

  width: 400px;

  @media (max-width: 959px) {
    width: 100%;
  }
`;

const StyledRegisterLabel = styled.p`
  font-family: "Polaris", "Arial", sans-serif;
  color: #ffffff;
  font-size: 24px;
  letter-spacing: 1.42857px;
  margin: 0;
`;

const StyledButtonSecondary = styled.button`
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid #fafafa;
  border-radius: 0;
  padding: 20px;
  cursor: pointer;
  letter-spacing: 1.42857px;
  // margin-right: 34px;

  &:hover {
    opacity: 0.8;
  }

  width: 200px;

  &.active {
    background-color: #0091d4;
    border: 1px solid #0091d4;
    width: auto;
    cursor: unset;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    &:hover {
      opacity: 1;
    }

    svg {
      margin-right: 20px;
    }
  }
`;

const StyledImageWrapper = styled(Box)`
  position: relative;
  background: url(${(props) => props.image}) no-repeat center;
  background-size: cover;
  height: 600px;
`;

const StyledOuterWrapper = styled(Box)``;

const StyledInnerWrapper = styled(Box)`
  position: relative;
  background: #273032;
  backdrop-filter: blur(53.1521px);
  width: 100%;
  z-index: 3;
  // display: flex;
  // justify-content: center;
  // align-items: center;

  h3 {
    margin: 0;
  }

  h5 {
    color: #0091d4;
    margin: 0;
    margin-bottom: 1rem;
    text-transform: none;
  }
`;

const StyledSideWrapper = styled(Box)`
  position: absolute;
  top: -153px;
  left: -176px;
  background-color: #161e20;
  width: 324px;

  @media (max-width: 959px) {
    position: relative;
    top: unset;
    left: unset;
    width: auto;
    margin-bottom: 24px;
  }
`;

const StyledSideWrapperInner = styled(Box)`
  padding: 32px 59px 32px 32px;
`;

const StyledSideWrapperTitle = styled.p`
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: 0.176471px;
  color: #0091d4;
  margin: 0;
  margin-bottom: 10px;

  @media (max-width: 599px) {
    font-size: 20px;
  }
`;

const StyledSideWrapperSubTitle = styled.p`
  font-family: "AzoSans Black", "Arial", sans-serif;
  font-size: 48px;
  line-height: 48px;
  letter-spacing: 0.352941px;
  margin: 0;
`;

const StyledTag = styled.span`
  border: 1px solid #0091d4;
  border-radius: 28px;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #878c92;
  font-family: "AzoSans Bold", "Arial", sans-serif;
  padding: 6px 16px 6px 16px;
  margin-right: 12px;
`;

const StyledTrainerName = styled.p`
  font-family: "AzoSans Bold", "Arial", sans-serif;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #0091d4;
  margin: 0;
  margin-bottom: 25px;
`;

const StyledTrainerDesc = styled.p`
  font-family: "AzoSans Regular", "Arial", sans-serif;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4375px;
  color: #a7acb3;
  margin: 0;
`;

const StyledConfigurationName = styled.p`
  font-family: "AzoSans Bold", "Arial", sans-serif;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #a7acb3;
  margin: 0;
  margin-bottom: 25px;
`;

const StyledConfigurationDetails = styled.p`
  font-family: "AzoSans Bold", "Arial", sans-serif;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4375px;
  color: #a7acb3;
  margin: 0;
`;

const StyledMainWrapper = styled(Box)``;

const StyledMainTitle = styled.p`
  font-family: "AzoSans Black", "Arial", sans-serif;
  font-size: 48px;
  line-height: 80px;
  letter-spacing: 0.352941px;
  color: #ffffff;
  margin: 0;
  margin-bottom: 31px;

  span {
    color: #0091d4;
  }

  @media (max-width: 599px) {
    font-size: 40px;
  }
`;

const StyledMainDesc = styled.p`
  font-size: 16px;
  line-height: 28px;
  text-align: justify;
  letter-spacing: 0.117647px;
  color: #a7acb3;
  margin: 0;
  margin-bottom: 50px;
`;

const StyledContactWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;

  @media (max-width: 1279px) {
    flex-wrap: wrap;
  }
`;

const StyledContactDetails = styled(Box)`
  @media (max-width: 1279px) {
    width: 100%;
    margin-bottom: 24px;
  }
`;

const StyledContactSocial = styled(Box)`
  display: flex;
  flex-wrap: nowrap;

  a {
    color: #a7acb3 !important;
    margin-right: 18px;

    &:hover {
      opacity: 0.8;
    }
  }

  @media (max-width: 1279px) {
    width: 100%;
  }
`;

const StyledContactTitle = styled.p`
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: 0.176471px;
  color: #0091d4;
  margin: 0;
  margin-bottom: 6px;
`;

const StyledContactDesc = styled.p`
  font-family: "AzoSans Medium", "Arial", sans-serif;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 0.0785714px;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 6px;
`;

const StyledContactLink = styled.a`
  font-family: "AzoSans Medium", "Arial", sans-serif;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 0.0785714px;
  text-transform: uppercase;
  color: #0091d4;
  text-decoration: none;
  margin: 0;

  &:visited {
    color: #0091d4;
  }
`;

const StyledBookingWrapper = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const StyledMapWrapper = styled(Box)`
  position: relative;
  // height: 500px;
  // Temporary height when map is disabled
  height: 75px;
  width: 100%;
  margin-bottom: 56px;
`;

// const StyledMapOverlay = styled(Box)`
//   position: absolute;
//   top: 0;
//   left: 0;
//   height: 100%;
//   width: 100%;
//   background: linear-gradient(
//     180deg,
//     rgba(0, 145, 212, 0.5) 0%,
//     rgba(22, 30, 32, 0.5) 100%
//   );
//   mix-blend-mode: normal;
//   opacity: 0.78;
//   pointer-events: none;
//   z-index: 1;
// `;

const SingleEvent = view((props) => {
  const params = useParams();
  const { id } = params;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [paymentDialogue, setPaymentDialogue] = useState(false);
  const [cancelPaymentDialogue, setCancelPaymentDialogue] = useState(false);

  let date;
  let month;
  let day;
  let time;
  let year;

  // useEffect(() => {
  //   events.getEvents();
  //   events.getSingleEvent(id);

  //   if (auth.user) {
  //     bookings.getUserBooking(id);
  //   }
  //   // eslint-disable-next-line
  // }, [id, auth.user]);

  autoEffect(() => {
    events.getEvents();
    events.getSingleEvent(id);

    if (auth.user) {
      bookings.getUserBooking(id);
    }
  });

  if (events.singleEvent) {
    date = moment(events.singleEvent.starts_at).format("D");
    month = moment(events.singleEvent.starts_at).format("MMM");
    day = moment(events.singleEvent.starts_at).format("dddd");
    time = moment(events.singleEvent.starts_at).format("h A");
    year = moment(events.singleEvent.starts_at).format("YYYY");
  }

  // const createMapOptions = () => {
  //   return {
  //     disableDefaultUI: true,
  //     styles: [
  //       {
  //         featureType: "all",
  //         elementType: "labels.text.fill",
  //         stylers: [
  //           {
  //             saturation: 36,
  //           },
  //           {
  //             color: "#000000",
  //           },
  //           {
  //             lightness: 40,
  //           },
  //         ],
  //       },
  //       {
  //         featureType: "all",
  //         elementType: "labels.text.stroke",
  //         stylers: [
  //           {
  //             visibility: "on",
  //           },
  //           {
  //             color: "#000000",
  //           },
  //           {
  //             lightness: 16,
  //           },
  //         ],
  //       },
  //       {
  //         featureType: "all",
  //         elementType: "labels.icon",
  //         stylers: [
  //           {
  //             visibility: "off",
  //           },
  //         ],
  //       },
  //       {
  //         featureType: "administrative",
  //         elementType: "geometry.fill",
  //         stylers: [
  //           {
  //             color: "#000000",
  //           },
  //           {
  //             lightness: 20,
  //           },
  //         ],
  //       },
  //       {
  //         featureType: "administrative",
  //         elementType: "geometry.stroke",
  //         stylers: [
  //           {
  //             color: "#000000",
  //           },
  //           {
  //             lightness: 17,
  //           },
  //           {
  //             weight: 1.2,
  //           },
  //         ],
  //       },
  //       {
  //         featureType: "landscape",
  //         elementType: "geometry",
  //         stylers: [
  //           {
  //             color: "#000000",
  //           },
  //           {
  //             lightness: 20,
  //           },
  //         ],
  //       },
  //       {
  //         featureType: "poi",
  //         elementType: "geometry",
  //         stylers: [
  //           {
  //             color: "#000000",
  //           },
  //           {
  //             lightness: 21,
  //           },
  //         ],
  //       },
  //       {
  //         featureType: "road.highway",
  //         elementType: "geometry.fill",
  //         stylers: [
  //           {
  //             color: "#000000",
  //           },
  //           {
  //             lightness: 17,
  //           },
  //         ],
  //       },
  //       {
  //         featureType: "road.highway",
  //         elementType: "geometry.stroke",
  //         stylers: [
  //           {
  //             color: "#000000",
  //           },
  //           {
  //             lightness: 29,
  //           },
  //           {
  //             weight: 0.2,
  //           },
  //         ],
  //       },
  //       {
  //         featureType: "road.arterial",
  //         elementType: "geometry",
  //         stylers: [
  //           {
  //             color: "#000000",
  //           },
  //           {
  //             lightness: 18,
  //           },
  //         ],
  //       },
  //       {
  //         featureType: "road.local",
  //         elementType: "geometry",
  //         stylers: [
  //           {
  //             color: "#000000",
  //           },
  //           {
  //             lightness: 16,
  //           },
  //         ],
  //       },
  //       {
  //         featureType: "transit",
  //         elementType: "geometry",
  //         stylers: [
  //           {
  //             color: "#000000",
  //           },
  //           {
  //             lightness: 19,
  //           },
  //         ],
  //       },
  //       {
  //         featureType: "water",
  //         elementType: "geometry",
  //         stylers: [
  //           {
  //             color: "#000000",
  //           },
  //           {
  //             lightness: 17,
  //           },
  //         ],
  //       },
  //     ],
  //   };
  // };

  const handlePaymentDialogue = () => {
    bookings.createUserBooking(events.singleEvent.id);
    setPaymentDialogue(true);
  };

  const handleCancelPaymentDialogue = () => {
    setCancelPaymentDialogue(true);
  };

  if (auth.user) {
    return (
      <Fragment>
        <StyledContainer fixed>
          {!events.events || !events.singleEvent || !bookings.singleBooking ? (
            <Fragment>
              <StyledSpacer />
              <Loader />
            </Fragment>
          ) : (
            <Fragment>
              <Grid container>
                <Grid item xs={12}>
                  <StyledBackButton to="/events">
                    <ArrowBackIcon fontSize="small" />
                    <span>back to events</span>
                  </StyledBackButton>
                </Grid>
              </Grid>
              <Grid container justify={"center"} alignItems={"center"}>
                <Grid item xs={12}>
                  <StyledEventWrapper>
                    <StyledDateWrapper>
                      <StyledDateDay>{date}</StyledDateDay>
                      <StyledDateMonth>{month}</StyledDateMonth>
                    </StyledDateWrapper>
                    <StyledTopWrapper>
                      <Box>
                        <StyledEventTime>
                          {day} <span>{time}</span> {year}
                        </StyledEventTime>
                      </Box>
                      <StyledTopInner>
                        {bookings.singleBooking.length === 0 ||
                        bookings.singleBooking[0].status === "pending" ||
                        bookings.singleBooking[0].status === "canceled" ? (
                          events.singleEvent.available_slots !== 0 ? (
                            <StyledButtonSecondary
                              onClick={handlePaymentDialogue}
                            >
                              book event
                            </StyledButtonSecondary>
                          ) : null
                        ) : (
                          <StyledButtonSecondary className="active">
                            <CheckIcon /> you are going
                          </StyledButtonSecondary>
                        )}

                        {/* <StyledShareIcon>
                          <img src={followIcon} alt="follow_icon" />
                        </StyledShareIcon> */}
                      </StyledTopInner>
                    </StyledTopWrapper>
                    <StyledEventTitle>
                      {events.singleEvent.title}
                    </StyledEventTitle>
                  </StyledEventWrapper>
                </Grid>
                <Grid item xs={12}>
                  <StyledImageWrapper image={events.singleEvent.image}>
                    {/* <StyledGradientOverlay />
                    <StyledDarkOverlay /> */}
                  </StyledImageWrapper>
                  <StyledOuterWrapper
                    style={
                      matches ? { paddingLeft: "200px" } : { paddingLeft: 0 }
                    }
                  >
                    <StyledInnerWrapper
                      style={
                        matches
                          ? { padding: "63px 88px 63px 148px" }
                          : { padding: "63px 24px 63px 24px" }
                      }
                      mb={12}
                    >
                      <StyledSideWrapper>
                        <StyledSideWrapperInner>
                          <StyledSideWrapperTitle>
                            seats left
                          </StyledSideWrapperTitle>
                          <StyledSideWrapperSubTitle>
                            {events.singleEvent.available_slots
                              ? events.singleEvent.available_slots
                              : 0}
                          </StyledSideWrapperSubTitle>
                        </StyledSideWrapperInner>
                        <StyledSideWrapperInner>
                          <StyledSideWrapperTitle>
                            skill level
                          </StyledSideWrapperTitle>
                          <StyledTag>
                            {events.singleEvent.skill_level
                              ? events.singleEvent.skill_level
                              : "-"}
                          </StyledTag>
                        </StyledSideWrapperInner>
                        <StyledSideWrapperInner>
                          <StyledSideWrapperTitle>price</StyledSideWrapperTitle>
                          <StyledSideWrapperSubTitle>
                            {events.singleEvent.price
                              ? events.singleEvent.price
                              : 0}{" "}
                            €
                          </StyledSideWrapperSubTitle>
                        </StyledSideWrapperInner>
                        <StyledSideWrapperInner>
                          <StyledSideWrapperTitle>
                            duration
                          </StyledSideWrapperTitle>
                          <StyledSideWrapperSubTitle>
                            {events.singleEvent.duration
                              ? events.singleEvent.duration
                              : "-"}{" "}
                            MIN
                          </StyledSideWrapperSubTitle>
                        </StyledSideWrapperInner>
                        <StyledSideWrapperInner>
                          <StyledSideWrapperTitle>
                            trainer
                          </StyledSideWrapperTitle>
                          <StyledTrainerName>
                            {events.singleEvent.trainer_title
                              ? events.singleEvent.trainer_title
                              : "-"}{" "}
                          </StyledTrainerName>
                          {events.singleEvent.trainer_description ? (
                            <StyledTrainerDesc>
                              {events.singleEvent.trainer_description}
                            </StyledTrainerDesc>
                          ) : null}
                        </StyledSideWrapperInner>
                        <StyledSideWrapperInner>
                          <StyledSideWrapperTitle>
                            configuration
                          </StyledSideWrapperTitle>
                          <StyledConfigurationName>
                            {events.singleEvent.Venue.configuration
                              ? events.singleEvent.Venue.configuration
                              : "-"}{" "}
                          </StyledConfigurationName>
                          {events.singleEvent.Venue.computer_configuration ? (
                            <StyledConfigurationDetails>
                              {events.singleEvent.Venue.computer_configuration}
                            </StyledConfigurationDetails>
                          ) : null}
                        </StyledSideWrapperInner>
                      </StyledSideWrapper>
                      <StyledMainWrapper px={matches ? 12 : 0}>
                        <StyledMainTitle>
                          Arena <span>Configuration</span> and{" "}
                          <span>Details</span>
                        </StyledMainTitle>
                        <StyledMainDesc>
                          {events.singleEvent.description}
                        </StyledMainDesc>
                      </StyledMainWrapper>
                      <StyledContactWrapper px={matches ? 6 : 0}>
                        <StyledContactDetails>
                          <StyledContactTitle>Contact</StyledContactTitle>
                          <StyledContactDesc>
                            {events.singleEvent.Venue
                              ? `${events.singleEvent.Venue.title}, ${events.singleEvent.Venue.address_line_1}, ${events.singleEvent.Venue.zip}, ${events.singleEvent.Venue.city}`
                              : "online"}
                          </StyledContactDesc>
                          <StyledContactDesc>
                            {events.singleEvent.Venue.phone
                              ? events.singleEvent.Venue.phone
                              : "-"}
                          </StyledContactDesc>
                          <StyledContactLink
                            href={events.singleEvent.Venue.website}
                            target="_blank"
                          >
                            {events.singleEvent.Venue.website
                              ? events.singleEvent.Venue.website
                              : "-"}
                          </StyledContactLink>
                        </StyledContactDetails>
                        <StyledContactSocial>
                          {events.singleEvent.Venue.twitter_link ? (
                            <a
                              href={events.singleEvent.Venue.twitter_link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <TwitterIcon fontSize="large" />
                            </a>
                          ) : null}
                          {events.singleEvent.Venue.linked_in_link ? (
                            <a
                              href={events.singleEvent.Venue.linked_in_link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <LinkedInIcon fontSize="large" />
                            </a>
                          ) : null}
                          {events.singleEvent.Venue.instagram_link ? (
                            <a
                              href={events.singleEvent.Venue.instagram_link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <InstagramIcon fontSize="large" />
                            </a>
                          ) : null}
                          {events.singleEvent.Venue.facebook_link ? (
                            <a
                              href={events.singleEvent.Venue.facebook_link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FacebookIcon fontSize="large" />
                            </a>
                          ) : null}
                        </StyledContactSocial>
                      </StyledContactWrapper>
                      <StyledMapWrapper>
                        {/* <StyledMapOverlay />
                        <GoogleMapReact
                          bootstrapURLKeys={{
                            key: "AIzaSyDv3n7nDJhZ9gbdfpDptvi2homWu3WcU40",
                          }}
                          center={{
                            lat: events.singleEvent.Venue.lat
                              ? parseInt(events.singleEvent.Venue.lat)
                              : 53.5500804,
                            lng: events.singleEvent.Venue.lng
                              ? parseInt(events.singleEvent.Venue.lng)
                              : 9.9540997,
                          }}
                          zoom={11}
                          options={createMapOptions}
                        >
                          <Marker
                            lat={
                              events.singleEvent.Venue.lat
                                ? parseInt(events.singleEvent.Venue.lat)
                                : 53.5500804
                            }
                            lng={
                              events.singleEvent.Venue.lng
                                ? parseInt(events.singleEvent.Venue.lng)
                                : 9.9540997
                            }
                          />
                        </GoogleMapReact> */}
                      </StyledMapWrapper>
                      <StyledBookingWrapper px={matches ? 6 : 0}>
                        {bookings.singleBooking.length === 0 ||
                        bookings.singleBooking[0].status === "pending" ||
                        bookings.singleBooking[0].status === "canceled" ? (
                          events.singleEvent.available_slots !== 0 ? (
                            <StyledButtonPrimary
                              onClick={handlePaymentDialogue}
                            >
                              book event
                            </StyledButtonPrimary>
                          ) : null
                        ) : (
                          <StyledButtonPrimary
                            onClick={handleCancelPaymentDialogue}
                          >
                            cancel booking
                          </StyledButtonPrimary>
                        )}
                        {/* <StyledShareIcon>
                          <img src={followIcon} alt="follow_icon" />
                        </StyledShareIcon> */}
                      </StyledBookingWrapper>
                    </StyledInnerWrapper>
                  </StyledOuterWrapper>
                </Grid>
              </Grid>
              <HeroNews />
              <PaymentForm
                paymentDialogue={paymentDialogue}
                setPaymentDialogue={setPaymentDialogue}
                event={events.singleEvent}
              />
              <CancelPayment
                cancelPaymentDialogue={cancelPaymentDialogue}
                setCancelPaymentDialogue={setCancelPaymentDialogue}
                event={events.singleEvent}
              />
            </Fragment>
          )}
        </StyledContainer>
        <Footer />
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <StyledContainer fixed>
          {!events.events || !events.singleEvent ? (
            <Fragment>
              <StyledSpacer />
              <Loader />
            </Fragment>
          ) : (
            <Fragment>
              <Grid container>
                <Grid item xs={12}>
                  <StyledBackButton to="/events">
                    <ArrowBackIcon fontSize="small" />
                    <span>back to events</span>
                  </StyledBackButton>
                </Grid>
              </Grid>
              <Grid container justify={"center"} alignItems={"center"}>
                <Grid item xs={12}>
                  <StyledEventWrapper>
                    <StyledDateWrapper>
                      <StyledDateDay>{date}</StyledDateDay>
                      <StyledDateMonth>{month}</StyledDateMonth>
                    </StyledDateWrapper>
                    <StyledTopWrapper>
                      <Box>
                        <StyledEventTime>
                          {day} <span>{time}</span> {year}
                        </StyledEventTime>
                      </Box>
                      <StyledTopInner>
                        {/* <StyledButtonSecondary onClick={handlePaymentDialogue}>
                          book event
                        </StyledButtonSecondary> */}
                      </StyledTopInner>
                    </StyledTopWrapper>
                    <StyledEventTitle>
                      {events.singleEvent.title}
                    </StyledEventTitle>
                  </StyledEventWrapper>
                </Grid>
                <Grid item xs={12}>
                  <StyledImageWrapper image={events.singleEvent.image}>
                    {/* <StyledGradientOverlay />
                    <StyledDarkOverlay /> */}
                  </StyledImageWrapper>
                  <StyledOuterWrapper
                    style={
                      matches ? { paddingLeft: "200px" } : { paddingLeft: 0 }
                    }
                  >
                    <StyledInnerWrapper
                      style={
                        matches
                          ? { padding: "63px 88px 63px 148px" }
                          : { padding: "63px 24px 63px 24px" }
                      }
                      mb={12}
                    >
                      <StyledSideWrapper>
                        <StyledSideWrapperInner>
                          <StyledSideWrapperTitle>
                            seats left
                          </StyledSideWrapperTitle>
                          <StyledSideWrapperSubTitle>
                            {events.singleEvent.available_slots
                              ? events.singleEvent.available_slots
                              : 0}
                          </StyledSideWrapperSubTitle>
                        </StyledSideWrapperInner>
                        <StyledSideWrapperInner>
                          <StyledSideWrapperTitle>
                            skill level
                          </StyledSideWrapperTitle>
                          <StyledTag>
                            {events.singleEvent.skill_level
                              ? events.singleEvent.skill_level
                              : "-"}
                          </StyledTag>
                        </StyledSideWrapperInner>
                        <StyledSideWrapperInner>
                          <StyledSideWrapperTitle>price</StyledSideWrapperTitle>
                          <StyledSideWrapperSubTitle>
                            {events.singleEvent.price
                              ? events.singleEvent.price
                              : 0}{" "}
                            €
                          </StyledSideWrapperSubTitle>
                        </StyledSideWrapperInner>
                        <StyledSideWrapperInner>
                          <StyledSideWrapperTitle>
                            duration
                          </StyledSideWrapperTitle>
                          <StyledSideWrapperSubTitle>
                            {events.singleEvent.duration
                              ? events.singleEvent.duration
                              : "-"}{" "}
                            MIN
                          </StyledSideWrapperSubTitle>
                        </StyledSideWrapperInner>
                        <StyledSideWrapperInner>
                          <StyledSideWrapperTitle>
                            trainer
                          </StyledSideWrapperTitle>
                          <StyledTrainerName>
                            {events.singleEvent.trainer_title
                              ? events.singleEvent.trainer_title
                              : "-"}{" "}
                          </StyledTrainerName>
                          {events.singleEvent.trainer_description ? (
                            <StyledTrainerDesc>
                              {events.singleEvent.trainer_description}
                            </StyledTrainerDesc>
                          ) : null}
                        </StyledSideWrapperInner>
                        <StyledSideWrapperInner>
                          <StyledSideWrapperTitle>
                            configuration
                          </StyledSideWrapperTitle>
                          <StyledConfigurationName>
                            {events.singleEvent.Venue.configuration
                              ? events.singleEvent.Venue.configuration
                              : "-"}{" "}
                          </StyledConfigurationName>
                          {events.singleEvent.Venue.computer_configuration ? (
                            <StyledConfigurationDetails>
                              {events.singleEvent.Venue.computer_configuration}
                            </StyledConfigurationDetails>
                          ) : null}
                        </StyledSideWrapperInner>
                      </StyledSideWrapper>
                      <StyledMainWrapper px={matches ? 12 : 0}>
                        <StyledMainTitle>
                          Arena <span>Configuration</span> and{" "}
                          <span>Details</span>
                        </StyledMainTitle>
                        <StyledMainDesc>
                          {events.singleEvent.description}
                        </StyledMainDesc>
                      </StyledMainWrapper>
                      <StyledContactWrapper px={matches ? 6 : 0}>
                        <StyledContactDetails>
                          <StyledContactTitle>Contact</StyledContactTitle>
                          <StyledContactDesc>
                            {events.singleEvent.Venue
                              ? `${events.singleEvent.Venue.title}, ${events.singleEvent.Venue.address_line_1}, ${events.singleEvent.Venue.zip}, ${events.singleEvent.Venue.city}`
                              : "online"}
                          </StyledContactDesc>
                          <StyledContactDesc>
                            {events.singleEvent.Venue.phone
                              ? events.singleEvent.Venue.phone
                              : "-"}
                          </StyledContactDesc>
                          <StyledContactLink
                            href={events.singleEvent.Venue.website}
                            target="_blank"
                          >
                            {events.singleEvent.Venue.website
                              ? events.singleEvent.Venue.website
                              : "-"}
                          </StyledContactLink>
                        </StyledContactDetails>
                        <StyledContactSocial>
                          {events.singleEvent.Venue.twitter_link ? (
                            <a
                              href={events.singleEvent.Venue.twitter_link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <TwitterIcon fontSize="large" />
                            </a>
                          ) : null}
                          {events.singleEvent.Venue.linked_in_link ? (
                            <a
                              href={events.singleEvent.Venue.linked_in_link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <LinkedInIcon fontSize="large" />
                            </a>
                          ) : null}
                          {events.singleEvent.Venue.instagram_link ? (
                            <a
                              href={events.singleEvent.Venue.instagram_link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <InstagramIcon fontSize="large" />
                            </a>
                          ) : null}
                          {events.singleEvent.Venue.facebook_link ? (
                            <a
                              href={events.singleEvent.Venue.facebook_link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FacebookIcon fontSize="large" />
                            </a>
                          ) : null}
                        </StyledContactSocial>
                      </StyledContactWrapper>
                      <StyledMapWrapper>
                        {/* <StyledMapOverlay />
                        <GoogleMapReact
                          bootstrapURLKeys={{
                            key: "AIzaSyDv3n7nDJhZ9gbdfpDptvi2homWu3WcU40",
                          }}
                          center={{
                            lat: events.singleEvent.Venue.lat
                              ? parseInt(events.singleEvent.Venue.lat)
                              : 53.5500804,
                            lng: events.singleEvent.Venue.lng
                              ? parseInt(events.singleEvent.Venue.lng)
                              : 9.9540997,
                          }}
                          zoom={11}
                          options={createMapOptions}
                        >
                          <Marker
                            lat={
                              events.singleEvent.Venue.lat
                                ? parseInt(events.singleEvent.Venue.lat)
                                : 53.5500804
                            }
                            lng={
                              events.singleEvent.Venue.lng
                                ? parseInt(events.singleEvent.Venue.lng)
                                : 9.9540997
                            }
                          />
                        </GoogleMapReact> */}
                      </StyledMapWrapper>
                      <StyledBookingWrapper px={matches ? 6 : 0}>
                        <StyledRegisterLabel>
                          log in to book the event
                        </StyledRegisterLabel>
                      </StyledBookingWrapper>
                    </StyledInnerWrapper>
                  </StyledOuterWrapper>
                </Grid>
              </Grid>
              <HeroNews />
              <PaymentForm
                paymentDialogue={paymentDialogue}
                setPaymentDialogue={setPaymentDialogue}
                event={events.singleEvent}
              />
              <CancelPayment
                cancelPaymentDialogue={cancelPaymentDialogue}
                setCancelPaymentDialogue={setCancelPaymentDialogue}
                event={events.singleEvent}
              />
            </Fragment>
          )}
        </StyledContainer>
        <Footer />
      </Fragment>
    );
  }
});

export default SingleEvent;
