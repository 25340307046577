import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import { Link } from "react-router-dom";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";

import logo from "../../assets/img/logo.svg";

const StyledFooterContainer = styled(Grid)`
  position: relative;
  z-index: 1;
  background: #161e20;
`;

const StyledFooterWrapper = styled(Grid)`
  padding: 54px 0 54px 0;

  h6 {
    margin: 0;
    margin-bottom: 2rem;
  }

  p {
    font-size: 16px;
    line-height: 36px;
    letter-spacing: 0.117647px;
    margin: 0;
    margin-bottom: 0.5rem;
  }

  a {
    color: #ffffff;
  }
`;

const StyledLeftWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;

  @media (max-width: 959px) {
    align-items: center;
    margin-bottom: 48px;
  }
`;

const StyledMiddleWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-bottom: 1.25rem;

  p {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.0882353px;
    margin-bottom: 0;
    text-align: center;
  }

  img {
    height: 80px;
    text-align: center;
  }

  @media (max-width: 959px) {
    align-items: center;
    margin-bottom: 48px;
  }
`;

const StyledRightWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;

  @media (max-width: 959px) {
    align-items: center;
  }
`;

const StyledIconsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
`;

const StyledLink = styled(Link)`
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.0882353px;
  margin-bottom: 0;
  text-align: center;
  text-decoration: none;
  margin-left: 1rem;
  margin-right: 1rem;

  &:hover {
    color: #0091d4;
  }
`;

const Footer = () => {
  return (
    <StyledFooterContainer container>
      <Container fixed>
        <StyledFooterWrapper item xs={12}>
          <Grid container>
            <Grid item xs={12} md={3}>
              <StyledLeftWrapper>
                <h6>Contact</h6>
                <p>Home United Spaces GmbH</p>
                <p>Nobistor 16</p>
                <p>22767 Hamburg</p>
                <p>gaming@unitedcyberspaces.com</p>
              </StyledLeftWrapper>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledMiddleWrapper>
                <Link to="/">
                  <img src={logo} alt="logo" />
                </Link>
                <p>
                  United Cyber Spaces isn’t endorsed by Riot Games and doesn’t
                  reflect the views or opinions of Riot Games or anyone
                  officially involved in producing or managing League of
                  Legends. League of Legends and Riot Games are trademarks or
                  registered trademarks of Riot Games, Inc. League of Legends ©
                  Riot Games, Inc.
                </p>
                <div>
                  <StyledLink to="/terms-and-conditions">
                    Terms and conditions
                  </StyledLink>
                  <StyledLink to="/privacy">Privacy policy</StyledLink>
                  <StyledLink to="/impressum">Impressum</StyledLink>
                </div>
                <p>Copyright © 2020 United Cyber Spaces</p>
              </StyledMiddleWrapper>
            </Grid>
            <Grid item xs={12} md={3}>
              <StyledRightWrapper>
                <h6>Social</h6>
                <StyledIconsWrapper>
                  <a
                    href="https://twitter.com/unitedcyberspc"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TwitterIcon fontSize="large" />
                  </a>
                  <a
                    href="https://www.instagram.com/unitedcyberspaces/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InstagramIcon fontSize="large" />
                  </a>
                  <a
                    href="https://www.facebook.com/unitedcyberspaces"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookIcon fontSize="large" />
                  </a>
                </StyledIconsWrapper>
              </StyledRightWrapper>
            </Grid>
          </Grid>
        </StyledFooterWrapper>
      </Container>
    </StyledFooterContainer>
  );
};

export default Footer;
