import React, { Fragment, useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover";
import Avatar from "@material-ui/core/Avatar";
import MenuItem from "@material-ui/core/MenuItem";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { view } from "@risingstack/react-easy-state";
import { useHistory, useLocation } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import profileIcon from "../../assets/img/profile_icon.png";
import logo from "../../assets/img/logo.svg";
import logoSmall from "../../assets/img/logo_small.svg";

import { auth } from "../../stores/BaseStore";
import Contact from "../Contact";

const StyledNavbarContainer = styled(Grid)`
  position: fixed;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 54px;
  left: 0;
  top: 0;
  transition: all 0.2s ease-in-out;

  img {
    width: 235px;
  }

  @media (max-width: 599px) {
    img {
      width: 150px;
    }
  }

  &.scrolled {
    background-color: #161e20;
    margin-top: 0;
    height: 88px;

    img {
      width: 75px;
    }
  }
`;

const StyledNavbarWrapper = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const StyledImageWrapper = styled(Box)`
  width: 235px;
`;

const StyledAvatar = styled(Avatar)`
  height: 68px;
  width: 68px;
  border: 2px solid #0091d4;
  cursor: pointer;
`;

const StyledLink = styled(Link)`
  color: #ffffff;
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 15px;
  text-decoration: none;
  margin-right: 48px;

  &:hover {
    color: #0091d4;
  }

  &.active {
    color: #0091d4;
  }
`;

const StyledAnchor = styled.a`
  color: #ffffff;
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 15px;
  text-decoration: none;
  margin-right: 48px;

  &:hover {
    color: #0091d4;
  }

  &.active {
    color: #0091d4;
  }
`;

const StyledFakeLink = styled.span`
  color: #ffffff;
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 15px;
  text-decoration: none;
  margin-right: 0;
  cursor: pointer;

  &:hover {
    color: #0091d4;
  }

  &.active {
    color: #0091d4;
  }
`;

const StyledDropdownLink = styled(Link)`
  font-family: "AzoSans Regular", "Arial", sans-serif;
  color: #ffffff;
  text-decoration: none;

  span {
    font-family: "AzoSans Regular", "Arial", sans-serif;
  }
`;

const StyledDropdownAnchor = styled.a`
  font-family: "AzoSans Regular", "Arial", sans-serif;
  color: #ffffff;
  text-decoration: none;

  span {
    font-family: "AzoSans Regular", "Arial", sans-serif;
  }
`;

const StyledFakeDropdownLink = styled.span`
  font-family: "AzoSans Regular", "Arial", sans-serif;
  color: #ffffff;
  text-decoration: none;

  span {
    font-family: "AzoSans Regular", "Arial", sans-serif;
  }
`;

const StyledList = styled(List)`
  background-color: #161e20;
`;

const Navbar = view(() => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [scrolled, setScrolled] = useState(false);
  const [contactDialogue, setContactDialogue] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  let history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = async () => {
    setAnchorEl(null);
    await auth.logout();
    history.push("/login");
  };

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const handleContactDialogue = () => {
    setContactDialogue(true);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
  });

  return (
    <Fragment>
      <StyledNavbarContainer container className={scrolled ? "scrolled" : null}>
        <Container fixed>
          <StyledNavbarWrapper item xs={12}>
            <StyledImageWrapper>
              <Link to="/">
                <img src={scrolled ? logoSmall : logo} alt="logo" />
              </Link>
            </StyledImageWrapper>
            {matches ? (
              <Fragment>
                <Box>
                  <StyledLink
                    to="/players"
                    className={
                      location.pathname.includes("/players") ? "active" : null
                    }
                  >
                    Players
                  </StyledLink>
                  <StyledLink
                    to="/events"
                    className={
                      location.pathname.includes("/events") ? "active" : null
                    }
                  >
                    Events
                  </StyledLink>
                  <StyledLink
                    to="/trainings"
                    className={
                      location.pathname.includes("/trainings") ? "active" : null
                    }
                  >
                    Trainings
                  </StyledLink>
                  {/* <StyledAnchor
                    href="https://www.eventbrite.de/o/united-cyber-spaces-31263886927"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={
                      location.pathname.includes("/events") ? "active" : null
                    }
                  >
                    Events
                  </StyledAnchor> */}
                  <StyledLink
                    to="/news"
                    className={
                      location.pathname.includes("/news") ? "active" : null
                    }
                  >
                    News
                  </StyledLink>
                  <StyledFakeLink onClick={handleContactDialogue}>
                    Contact
                  </StyledFakeLink>
                </Box>
                <Box>
                  <StyledAvatar
                    alt="profile picture"
                    src={profileIcon}
                    aria-controls="profile-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                  />
                  <Popover
                    id="profile-menu"
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    disableScrollLock={true}
                  >
                    {auth.isLoggedIn ? (
                      <Fragment>
                        <MenuItem onClick={handleClose}>
                          <StyledDropdownLink to="/profile">
                            Profile
                          </StyledDropdownLink>
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                          <StyledDropdownLink to="/accept-terms">
                            Privacy settings
                          </StyledDropdownLink>
                        </MenuItem>
                        <MenuItem onClick={logout}>
                          <StyledFakeDropdownLink>
                            Logout
                          </StyledFakeDropdownLink>
                        </MenuItem>
                      </Fragment>
                    ) : (
                      <MenuItem onClick={handleClose}>
                        <StyledDropdownLink to="/login">
                          Login
                        </StyledDropdownLink>
                      </MenuItem>
                    )}
                  </Popover>
                </Box>
              </Fragment>
            ) : (
              <Fragment>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                >
                  <MenuIcon fontSize="large" />
                </IconButton>
                <Drawer
                  anchor="top"
                  open={open}
                  onClose={handleDrawerClose}
                  disableScrollLock={true}
                >
                  <StyledList onClick={handleDrawerClose}>
                    <StyledDropdownLink to="/players">
                      <ListItem button>
                        <ListItemText>Players</ListItemText>
                      </ListItem>
                    </StyledDropdownLink>
                    <StyledDropdownLink to="/events">
                      <ListItem button>
                        <ListItemText>Events</ListItemText>
                      </ListItem>
                    </StyledDropdownLink>
                    {/* <StyledDropdownAnchor
                      href="https://www.eventbrite.de/o/united-cyber-spaces-31263886927"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ListItem button>
                        <ListItemText>Events</ListItemText>
                      </ListItem>
                    </StyledDropdownAnchor> */}
                    <StyledDropdownLink to="/news">
                      <ListItem button>
                        <ListItemText>News</ListItemText>
                      </ListItem>
                    </StyledDropdownLink>
                    <ListItem button onClick={handleContactDialogue}>
                      <ListItemText>
                        <StyledFakeDropdownLink>Contact</StyledFakeDropdownLink>
                      </ListItemText>
                    </ListItem>
                  </StyledList>
                  <Divider />
                  <StyledList onClick={handleDrawerClose}>
                    {auth.isLoggedIn ? (
                      <Fragment>
                        <StyledDropdownLink to="/profile">
                          <ListItem button>
                            <ListItemText>Profile</ListItemText>
                          </ListItem>
                        </StyledDropdownLink>
                        <StyledDropdownLink to="/accept-terms">
                          <ListItem button>
                            <ListItemText>Privacy settings</ListItemText>
                          </ListItem>
                        </StyledDropdownLink>
                        <ListItem button onClick={logout}>
                          <ListItemText>
                            <StyledFakeDropdownLink>
                              Logout
                            </StyledFakeDropdownLink>
                          </ListItemText>
                        </ListItem>
                      </Fragment>
                    ) : (
                      <StyledDropdownLink to="/login">
                        <ListItem button>
                          <ListItemText>Login</ListItemText>
                        </ListItem>
                      </StyledDropdownLink>
                    )}
                  </StyledList>
                </Drawer>
              </Fragment>
            )}
          </StyledNavbarWrapper>
        </Container>
      </StyledNavbarContainer>
      <Contact
        contactDialogue={contactDialogue}
        setContactDialogue={setContactDialogue}
      />
    </Fragment>
  );
});

export default Navbar;
