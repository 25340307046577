import React, { Fragment } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import { view } from "@risingstack/react-easy-state";
import Chart from "react-apexcharts";

import jungleIcon from "../../assets/img/position_jungle.png";
// import middleIcon from "../../assets/img/position_middle.png";
import rankIcon from "../../assets/img/rank_icon.png";

const StyledTotal = styled.p`
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.375px;
  color: #a7acb3;
  margin: 0;
`;

const StyledRatio = styled.p`
  font-family: "AzoSans Black", "Arial", sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #0091d4;
  text-transform: uppercase;
  margin: 0;
`;

const StyledKDA = styled.p`
  font-family: "AzoSans Medium", "Arial", sans-serif;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.0857143px;
  color: #ffffff;
  margin: 0;

  span {
    color: #0091d4;
  }
`;

const StyledPositionOuter = styled(Box)`
  margin-bottom: 15px;
`;

const StyledPositionInner = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 14px;

  img {
    margin-right: 18px;
  }
`;

const StyledRankWrapper = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

const StyledRankInner = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  margin-bottom: 24px;
`;

const StyledMedalWrapper = styled(Box)`
  img {
    height: 40px;
    width: 40px;
    margin-bottom: 0;
    margin-right: 4px;
  }
`;

const StyledMedalInfo = styled(Box)`
  padding-top: 4px;
`;

const StyledMedalMode = styled.p`
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.0857143px;
  color: #a7acb3;
  margin: 0;
`;

const StyledMedalRank = styled.p`
  font-family: "AzoSans Bold", "Arial", sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #0091d4;
  margin: 0;
`;

const StyledMedalRatio = styled.p`
  font-family: "AzoSans Medium", "Arial", sans-serif;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.0857143px;
  color: #ffffff;
  margin: 0;

  span {
    color: #0091d4;
  }
`;

const PlayerStatsLol = view((props) => {
  const { playerDetails } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const totalWins = playerDetails.SummonerStatistics.reduce((tot, arr) => {
    return tot + arr.wins;
  }, 0);

  const totalLosses = playerDetails.SummonerStatistics.reduce((tot, arr) => {
    return tot + arr.losses;
  }, 0);

  const percentage = totalWins
    ? ((totalWins / (totalWins + totalLosses)) * 100).toFixed(2)
    : 0;

  const chart = {
    series: [percentage],
    options: {
      chart: {
        height: 200,
        type: "radialBar",
      },
      colors: ["#0091d4"],
      plotOptions: {
        radialBar: {
          labels: {
            show: false,
          },
          track: {
            show: true,
            background: "#080023",
            strokeWidth: "97%",
            opacity: 1,
            margin: 4,
          },
          dataLabels: {
            show: true,
            name: {
              show: false,
            },
            value: {
              show: true,
              offsetY: 5,
              fontSize: "18px",
              fontFamily: '"AzoSans Black", "Arial", sans-serif',
              color: "#0091D4",
            },
          },
        },
      },
      labels: ["Cricket"],
    },
  };

  return (
    <Fragment>
      <Grid
        container
        justify={"flex-start"}
        style={matches ? null : { backgroundColor: "#161E20", padding: "24px" }}
      >
        <Grid item xs={12} lg={4}>
          <StyledTotal>Total</StyledTotal>
          <StyledRatio>
            {totalWins + totalLosses}G {totalWins}W {totalLosses}L
          </StyledRatio>
          <div>
            <Chart
              options={chart.options}
              series={chart.series}
              type="radialBar"
              style={{ marginLeft: "-38px" }}
              height="200px"
              width="200px"
            />
          </div>
        </Grid>
        <Grid item xs={12} lg>
          <StyledKDA>5.6 / 6.9 / 9.8</StyledKDA>
          <StyledRatio style={{ marginBottom: "28px" }}>
            2.24:1 (54%)
          </StyledRatio>
          <StyledTotal style={{ marginBottom: "24px" }}>
            Preferred Position (Rank)
          </StyledTotal>
          <StyledPositionOuter>
            <StyledPositionInner>
              <img src={jungleIcon} alt="position_jungle" />
              <StyledRatio>{playerDetails.preferred_position}</StyledRatio>
            </StyledPositionInner>
            {/* <StyledKDA>
              <span>38%</span> | Win Ratio <span>50%</span>
            </StyledKDA> */}
          </StyledPositionOuter>
        </Grid>
        <Grid item xs={12}>
          <StyledRankWrapper>
            {playerDetails.SummonerStatistics.length > 0
              ? playerDetails.SummonerStatistics.map((mode, index) => {
                  return (
                    <StyledRankInner
                      style={{ marginRight: "16px" }}
                      key={index}
                    >
                      <StyledMedalWrapper>
                        <img src={rankIcon} alt="rank_icon" />
                      </StyledMedalWrapper>
                      <StyledMedalInfo>
                        <StyledMedalMode>{mode.queue}</StyledMedalMode>
                        <StyledMedalRank>{mode.tier}</StyledMedalRank>
                        <StyledMedalRatio>
                          {mode.wins + mode.losses}G {mode.wins}W {mode.losses}L
                        </StyledMedalRatio>
                        <StyledMedalRatio>
                          Win Ratio{" "}
                          <span>
                            {(
                              (mode.wins / (mode.wins + mode.losses)) *
                              100
                            ).toFixed(2)}
                            %
                          </span>
                        </StyledMedalRatio>
                      </StyledMedalInfo>
                    </StyledRankInner>
                  );
                })
              : null}
          </StyledRankWrapper>
        </Grid>
      </Grid>
    </Fragment>
  );
});

export default PlayerStatsLol;
