import React, { Fragment, useState } from "react";
import GdprFooter from "../shared/GdprFooter";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FormGroup from "@material-ui/core/FormGroup";
import { Formik, Form, Field } from "formik";
import { CheckboxWithLabel } from "formik-material-ui";
import FormHelperText from "@material-ui/core/FormHelperText";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import styled from "styled-components";
import { view } from "@risingstack/react-easy-state";
import { gdpr } from "../../stores/BaseStore";

// import { gdpr } from "../../stores/BaseStore";
import Loader from "../shared/Loader";

const StyledContainer = styled(Container)`
  position: relative;
  z-index: 2;
  padding-top: 230px;
`;

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const StyledWrapperOuterList = styled(Box)`
  padding: 56px;
  display: flex;
  flex-direction: column;
  background-color: #273032;

  // h2 {
  //   font-family: unset;
  //   font-size: 3.75rem;
  //   font-weight: 300;
  //   line-height: 1.2;
  //   letter-spacing: -0.00833em;
  // }

  h2 {
    margin: 0;
    margin-bottom: 56px;

    span {
      color: #0091d4;
    }
  }

  h3 {
    font-family: unset;
    font-size: 3rem;
    font-weight: 400;
    line-height: 1.167;
    letter-spacing: 0em;
  }

  h4 {
    font-family: unset;
    font-size: 2.125rem;
    font-weight: 400;
    line-height: 1.235;
    letter-spacing: 0.00735em;
  }

  h5 {
    font-family: unset;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.334;
    letter-spacing: 0em;
  }

  h6 {
    font-family: unset;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
  }

  @media (max-width: 959px) {
    padding: 24px;

    h2 {
      font-size: 30px;
      line-height: unset;
      margin-bottom: 12px;
    }
  }
`;

const StyledWrapperOuter = styled(Box)`
  height: 60vh;
  padding: 56px;
  display: flex;
  flex-direction: column;
  background-color: #273032;

  // h2 {
  //   font-family: unset;
  //   font-size: 3.75rem;
  //   font-weight: 300;
  //   line-height: 1.2;
  //   letter-spacing: -0.00833em;
  // }

  h2 {
    margin: 0;
    margin-bottom: 56px;

    span {
      color: #0091d4;
    }
  }

  h3 {
    font-family: unset;
    font-size: 3rem;
    font-weight: 400;
    line-height: 1.167;
    letter-spacing: 0em;
  }

  h4 {
    font-family: unset;
    font-size: 2.125rem;
    font-weight: 400;
    line-height: 1.235;
    letter-spacing: 0.00735em;
  }

  h5 {
    font-family: unset;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.334;
    letter-spacing: 0em;
  }

  h6 {
    font-family: unset;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
  }

  @media (max-width: 959px) {
    padding: 24px;

    h2 {
      font-size: 30px;
      line-height: unset;
      margin-bottom: 12px;
    }
  }
`;

const StyledWrapperInnerList = styled(Box)`
  padding: 24px 24px 24px 0;
  margin-bottom: 82px;

  @media (max-width: 959px) {
    margin-bottom: 24px;
  }
`;

const StyledWrapperInner = styled(Box)`
  overflow-y: scroll;
  padding: 24px 24px 24px 0;
  margin-bottom: 82px;

  @media (max-width: 959px) {
    margin-bottom: 24px;
  }
`;

const StyledButtonPrimary = styled.button`
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  background-color: #0091d4;
  border: 1px solid #0091d4;
  border-radius: 0;
  padding: 20px;
  cursor: pointer;
  letter-spacing: 1.42857px;
  margin-top: 2rem;

  &:hover {
    opacity: 0.8;
  }

  width: 250px;
`;

const StyledCheckboxWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 12px;

  svg {
    cursor: pointer;
  }
`;

const TermsAndConditions = view(() => {
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [error, setError] = useState(null);

  const handleClick = (term) => {
    setSelectedTerm(term);

    if (selectedTerm) {
      if (selectedTerm.id === term.id) {
        setSelectedTerm(null);
      }
    }
  };

  return (
    <Fragment>
      <StyledContainer fixed>
        {!gdpr.terms ? (
          <Fragment>
            <StyledSpacer />
            <Loader />
          </Fragment>
        ) : (
          <Grid container style={{ marginBottom: "88px" }} spacing={4}>
            <Grid item xs={12} lg={6}>
              <StyledWrapperOuterList>
                <StyledWrapperInnerList>
                  <h4>Privacy settings</h4>
                  <Formik
                    initialValues={gdpr.terms.reduce(
                      (o, key) =>
                        Object.assign(o, {
                          [key.id]: key.accepted ? true : false,
                        }),
                      {}
                    )}
                    validate={(values) => {
                      let errors = [];

                      const filter = gdpr.terms.filter((term) => {
                        return term.required && !term.accepted;
                      });

                      const filterCheck = filter.filter((term) => {
                        const box = Object.keys(values).find((key) => {
                          return key === term.id.toString();
                        });

                        if (box && !values[box]) {
                          setError("Some fields are required");
                          return box;
                        } else {
                          return null;
                        }
                      });

                      errors = filterCheck;

                      /* const filter = gdpr.terms.find((term) => {
                        return term.required && !term.accepted;
                      });

                      const check = gdpr.terms.filter((term) => {
                        return term.required && !term.accepted;
                      });

                      const box = Object.keys(values).find((key) => {
                        return key === filter.id.toString();
                      });

                      if (check.length > 0) {
                        errors = filter;
                      }

                      if (box && !values[box]) {
                        setError(filter.name);
                        errors[box] = "Required";
                      } else {
                        setError(null);
                      }

                      console.log(errors); */

                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      setTimeout(() => {
                        setSubmitting(false);
                        gdpr.acceptTerms(values);
                      }, 500);
                    }}
                  >
                    {({ submitForm, isSubmitting }) => (
                      <Form>
                        <FormGroup>
                          {gdpr.terms.map((term) => {
                            if (term.required) {
                              return (
                                <StyledCheckboxWrapper key={term.id}>
                                  <div>
                                    <Field
                                      component={CheckboxWithLabel}
                                      type="checkbox"
                                      name={term.id.toString()}
                                      Label={{ label: term.name }}
                                      checked={term.accepted ? true : false}
                                      color="primary"
                                    />
                                    <span>*</span>
                                  </div>
                                  <ArrowRightAltIcon
                                    fontSize="large"
                                    onClick={() => handleClick(term)}
                                  />
                                </StyledCheckboxWrapper>
                              );
                            } else {
                              return (
                                <StyledCheckboxWrapper key={term.id}>
                                  <Field
                                    component={CheckboxWithLabel}
                                    type="checkbox"
                                    name={term.id.toString()}
                                    Label={{ label: term.name }}
                                    checked={term.accepted ? true : false}
                                    color="primary"
                                  />

                                  <ArrowRightAltIcon
                                    fontSize="large"
                                    onClick={() => handleClick(term)}
                                  />
                                </StyledCheckboxWrapper>
                              );
                            }
                          })}
                        </FormGroup>
                        {error ? (
                          <FormHelperText>{error}</FormHelperText>
                        ) : null}
                        {isSubmitting && <Loader />}
                        <br />
                        <StyledButtonPrimary
                          type="button"
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting}
                          onClick={submitForm}
                        >
                          Submit
                        </StyledButtonPrimary>
                      </Form>
                    )}
                  </Formik>
                </StyledWrapperInnerList>
              </StyledWrapperOuterList>
            </Grid>
            {selectedTerm ? (
              <Grid item xs={12} lg={6}>
                <StyledWrapperOuter>
                  <h2>{selectedTerm.name}</h2>
                  <StyledWrapperInner>{selectedTerm.terms}</StyledWrapperInner>
                </StyledWrapperOuter>
              </Grid>
            ) : null}
          </Grid>
        )}
      </StyledContainer>
      <GdprFooter />
    </Fragment>
  );
});

export default TermsAndConditions;
