import React from "react";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import { Link } from "react-router-dom";

import avatar from "../../assets/img/avatar_03.png";
import rankIcon from "../../assets/img/rank_icon.png";

const StyledLink = styled(Link)`
  all: unset;
  cursor: pointer;
`;

const StyledOuterWrapper = styled(Box)`
  background-color: #161e20;
  padding: 24px;
  margin-bottom: 40px;
  display: flex;
  position: relative;
`;

const StyledNumber = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  height: 52px;
  width: 52px;
  background-color: #0091d4;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 21px;
  line-height: 16px;
  letter-spacing: 1.875px;
  text-transform: uppercase;
`;

const StyledImageWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  margin-right: 24px;
`;

const StyledAvatar = styled.img`
  margin-bottom: 16px;
`;

const StyledInfoWrapper = styled(Box)`
  padding-top: 8px;
`;

const StyledSkill = styled.p`
  font-family: "AzoSans Bold", "Arial", sans-serif;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.0857143px;
  text-transform: uppercase;
  color: #878c92;
  margin: 0;
`;

const StyledName = styled.p`
  font-family: "AzoSans Black", "Arial", sans-serif;
  font-size: 21px;
  line-height: 25px;
  letter-spacing: 0.154412px;
  margin: 0;
  margin-bottom: 6px;
`;

const StyledRank = styled.p`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.114286px;
  color: #0091d4;
  margin: 0;
  margin-bottom: 24px;

  span {
    font-family: "AzoSans Black", "Arial", sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }
`;

const StyledMode = styled.p`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.114286px;
  color: #878c92;
  margin: 0;
  margin-bottom: 4px;
`;

const StyledRatio = styled.p`
  font-family: "AzoSans Black", "Arial", sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.114286px;
  color: #0091d4;
  margin: 0;
  margin-bottom: 24px;
  margin-top: 12px;
`;

const StyledTagWrapper = styled(Box)`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 8px;
`;

const StyledTag = styled.div`
  border: 1px solid #878c92;
  border-radius: 28px;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #878c92;
  font-family: "AzoSans Bold", "Arial", sans-serif;
  padding: 6px 16px 6px 16px;
  margin-right: 12px;
`;

const PlayerBoxLol = (props) => {
  const { singlePlayer } = props;
  let playerMode;

  if (singlePlayer) {
    playerMode = singlePlayer.SummonerStatistics[0];
  }

  return (
    <StyledLink to={`/players/lol/${singlePlayer.id}`}>
      <StyledOuterWrapper>
        <StyledNumber>{singlePlayer.id}</StyledNumber>
        <StyledImageWrapper>
          <StyledAvatar src={avatar} alt="avatar" />
          <img src={rankIcon} alt="rank_icon" />
        </StyledImageWrapper>
        <StyledInfoWrapper>
          <StyledSkill>Veteran</StyledSkill>
          <StyledName>{singlePlayer.summoner_name}</StyledName>
          <StyledRank>
            {playerMode ? playerMode.tier : "NO DATA"}{" "}
            <span>level {singlePlayer.summoner_level}</span>
          </StyledRank>
          <StyledMode>{playerMode ? playerMode.queue : "NO DATA"}</StyledMode>
          <StyledRatio>
            {playerMode
              ? `${playerMode.wins + playerMode.losses}G ${playerMode.wins}W ${
                  playerMode.losses
                }L`
              : "NO DATA"}
          </StyledRatio>
          <StyledTagWrapper>
            <StyledTag>{singlePlayer.server}</StyledTag>
          </StyledTagWrapper>
        </StyledInfoWrapper>
      </StyledOuterWrapper>
    </StyledLink>
  );
};

export default PlayerBoxLol;
