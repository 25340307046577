import React, { Fragment } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";
import styled from "styled-components";
import { view } from "@risingstack/react-easy-state";

import { auth } from "../../stores/BaseStore";
import { player } from "../../stores/BaseStore";

import playerAvatar from "../../assets/img/profile_avatar.png";

import ProfileEdit from "./ProfileEdit";
import PlayerEditLol from "./PlayerEditLol";
import PlayerEditDota from "./PlayerEditDota";

// const StyledDarkOverlay = styled(Box)`
//   position: absolute;
//   height: 100%;
//   width: 100%;
//   top: 0;
//   left: 0;
//   background: #0f1a26;
//   mix-blend-mode: normal;
//   opacity: 0.3;
//   z-index: 2;
// `;

// const StyledGradientOverlay = styled(Box)`
//   position: absolute;
//   height: 100%;
//   width: 100%;
//   top: 0;
//   left: 0;
//   background: linear-gradient(
//     180deg,
//     rgba(226, 6, 127, 0.5) 0%,
//     rgba(49, 0, 67, 0.5) 100%
//   );
//   mix-blend-mode: normal;
//   transform: matrix(1, 0, 0, -1, 0, 0);
//   opacity: 0.78;
//   z-index: 1;
// `;

const StyledTitleWrapper = styled(Box)`
  margin: 0;

  @media (max-width: 959px) {
    margin-bottom: 24px;
  }
`;

const StyledButtonSecondary = styled.button`
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid #fafafa;
  border-radius: 0;
  padding: 20px;
  cursor: pointer;
  letter-spacing: 1.42857px;

  &:hover {
    opacity: 0.8;
  }

  width: 320px;
`;

const StyledOuterWrapper = styled(Box)`
  position: relative;
  background: #273032;
  backdrop-filter: blur(53.1521px);
  width: 100%;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;

  h3 {
    margin: 0;
  }

  h5 {
    color: #0091d4;
    margin: 0;
    margin-bottom: 1rem;
    text-transform: none;
  }

  @media (max-width: 959px) {
    h3 {
      font-size: 32px;
    }
  }
`;

const StyledInnerWrapper = styled(Box)`
  position: relative;
  z-index: 3;
  margin-bottom: 4rem;
`;

const StyledSubtitle = styled.p`
  font-family: "AzoSans Bold", "Arial", sans-serif;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.75px;

  @media (max-width: 959px) {
    font-size: 16px;
  }
`;

const StyledList = styled.ul`
  padding-left: 1rem;

  li {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4375px;
    color: #a7acb3;
    margin-bottom: 1.5rem;

    span {
      font-family: "AzoSans Bold", "Arial", sans-serif;
      text-transform: uppercase;
    }
  }
`;

const StyledPlayerInfo = styled(Box)`
  position: absolute;
  top: -20px;
  left: 0;
  width: 336px;
  background: #161e20;
  z-index: 4;

  @media (max-width: 959px) {
    position: relative;
    width: 100%;
  }
`;

const StyledImageWrapper = styled(Box)`
  height: 336px;
  width: 100%;
  background: url(${playerAvatar}) no-repeat center;
  background-size: cover;
`;

const StyledInfoOuter = styled(Box)``;

const StyledInfoInner = styled(Box)`
  padding: 32px;
`;

const StyledGameAccount = styled.p`
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: 0.176471px;
  color: #0091d4;
  margin: 0;
  margin-bottom: 20px;
`;

const StyledLinkWrapper = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  cursor: pointer;
`;

const StyledGameLink = styled.p`
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1.42857px;
  margin: 0;
`;

const StyledDetailsWrapper = styled(Box)`
  width: 100%;
  padding-left: 157px;

  @media (max-width: 959px) {
    padding-left: 0;
  }
`;

const ProfileBox = view(() => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [profileDialogue, setProfileDialogue] = React.useState(false);
  const [lolDialogue, setLolDialogue] = React.useState(false);
  const [dotaDialogue, setDotaDialogue] = React.useState(false);

  const handleOpenProfileDialogue = () => {
    setProfileDialogue(true);
  };

  const handleOpenLolDialogue = () => {
    setLolDialogue(true);
  };

  const handleOpenDotaDialogue = () => {
    setDotaDialogue(true);
  };

  return auth.user ? (
    <Fragment>
      <Grid
        container
        justify={"space-between"}
        alignItems={"center"}
        style={{ marginBottom: "64px" }}
      >
        <StyledTitleWrapper>
          <h4 style={{ margin: 0 }}>Account details</h4>
        </StyledTitleWrapper>
        <Box>
          <StyledButtonSecondary onClick={handleOpenProfileDialogue}>
            Edit account details
          </StyledButtonSecondary>
        </Box>
      </Grid>
      <Grid container style={{ position: "relative" }}>
        <Grid item xs={12}>
          <StyledPlayerInfo>
            <StyledImageWrapper />
            <StyledInfoOuter>
              <StyledInfoInner>
                <StyledGameAccount>lol account</StyledGameAccount>
                <StyledLinkWrapper onClick={handleOpenLolDialogue}>
                  <AddIcon style={{ fontSize: "20px", marginRight: "24px" }} />
                  {player.playerLol ? (
                    <StyledGameLink>
                      {player.playerLol.summoner_name} (
                      {player.playerLol.server})
                    </StyledGameLink>
                  ) : (
                    <StyledGameLink>add lol summoner name</StyledGameLink>
                  )}
                </StyledLinkWrapper>
              </StyledInfoInner>
              <StyledInfoInner>
                <StyledGameAccount>dota account</StyledGameAccount>
                <StyledLinkWrapper onClick={handleOpenDotaDialogue}>
                  <AddIcon style={{ fontSize: "20px", marginRight: "24px" }} />
                  {player.playerDota ? (
                    <StyledGameLink>
                      {player.playerDota.dota2_account_id}
                    </StyledGameLink>
                  ) : (
                    <StyledGameLink>add dota 2 ID</StyledGameLink>
                  )}
                </StyledLinkWrapper>
              </StyledInfoInner>
            </StyledInfoOuter>
          </StyledPlayerInfo>
          <Grid container justify={"flex-end"}>
            <Grid
              item
              xs={12}
              style={matches ? { paddingLeft: "157px" } : { paddingLeft: 0 }}
            >
              <StyledOuterWrapper px={matches ? 12 : 6} py={12} mb={12}>
                <StyledDetailsWrapper>
                  <StyledInnerWrapper>
                    <h5>Full Name</h5>
                    {auth.user.attributes.name ? (
                      <h3>{auth.user.attributes.name}</h3>
                    ) : (
                      <StyledSubtitle>-</StyledSubtitle>
                    )}
                  </StyledInnerWrapper>
                  <StyledInnerWrapper>
                    <h5>Contact details</h5>
                    <StyledSubtitle>Address</StyledSubtitle>
                    <StyledList>
                      <li>
                        <span>Street name and number: </span>
                        {auth.user.attributes["custom:street"] || "-"}
                      </li>
                      <li>
                        <span>ZIP code: </span>
                        {auth.user.attributes["custom:zip"] || "-"}
                      </li>
                      <li>
                        <span>City: </span>
                        {auth.user.attributes["custom:city"] || "-"}
                      </li>
                      <li>
                        <span>Country: </span>
                        {auth.user.attributes["custom:country"] || "-"}
                      </li>
                    </StyledList>
                  </StyledInnerWrapper>
                  <StyledInnerWrapper>
                    <h5>Email</h5>
                    <StyledSubtitle>
                      {auth.user.attributes.email || "-"}
                    </StyledSubtitle>
                  </StyledInnerWrapper>
                  {/* <StyledInnerWrapper>
                    <h5>Phone</h5>
                    <StyledSubtitle>
                      {auth.user.attributes.phone_number || "-"}
                    </StyledSubtitle>
                  </StyledInnerWrapper> */}
                </StyledDetailsWrapper>
              </StyledOuterWrapper>
            </Grid>
          </Grid>
        </Grid>

        <ProfileEdit
          profileDialogue={profileDialogue}
          setProfileDialogue={setProfileDialogue}
        />
        <PlayerEditLol
          lolDialogue={lolDialogue}
          setLolDialogue={setLolDialogue}
        />
        <PlayerEditDota
          dotaDialogue={dotaDialogue}
          setDotaDialogue={setDotaDialogue}
        />
      </Grid>
    </Fragment>
  ) : null;
});

export default ProfileBox;
