import React, { Fragment } from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import { view, autoEffect } from "@risingstack/react-easy-state";

import { auth } from "../../stores/BaseStore";
import { player } from "../../stores/BaseStore";
import Loader from "../shared/Loader";
import ProfileBox from "../profile/ProfileBox";
import ProfileInvoices from "../profile/ProfileInvoices";
import HeroEvents from "../shared/HeroEvents";
import Footer from "../shared/Footer";

const StyledContainer = styled(Container)`
  position: relative;
  z-index: 2;
  padding-top: 230px;
`;

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const Profile = view(() => {
  autoEffect(() => {
    player.getUserData();
  });

  return (
    <Fragment>
      <StyledContainer fixed>
        {!auth.user ? (
          <Fragment>
            <StyledSpacer />
            <Loader />
          </Fragment>
        ) : (
          <Fragment>
            <ProfileBox />
            <ProfileInvoices />
            <HeroEvents />
          </Fragment>
        )}
      </StyledContainer>
      <Footer />
    </Fragment>
  );
});

export default Profile;
