import React, { Fragment } from "react";
import GdprFooter from "../shared/GdprFooter";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";

// import Loader from "../shared/Loader";

const StyledContainer = styled(Container)`
  position: relative;
  z-index: 2;
  padding-top: 230px;
`;

// const StyledSpacer = styled(Box)`
//   min-height: 100vh;
//   width: 100%;
// `;

const StyledWrapperOuter = styled(Box)`
  height: 60vh;
  padding: 56px 112px;
  display: flex;
  flex-direction: column;
  background-color: #273032;

  // h2 {
  //   font-family: unset;
  //   font-size: 3.75rem;
  //   font-weight: 300;
  //   line-height: 1.2;
  //   letter-spacing: -0.00833em;
  // }

  h2 {
    margin: 0;
    margin-bottom: 56px;

    span {
      color: #0091d4;
    }
  }

  h3 {
    font-family: unset;
    font-size: 3rem;
    font-weight: 400;
    line-height: 1.167;
    letter-spacing: 0em;
  }

  h4 {
    font-family: unset;
    font-size: 2.125rem;
    font-weight: 400;
    line-height: 1.235;
    letter-spacing: 0.00735em;
  }

  h5 {
    font-family: unset;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.334;
    letter-spacing: 0em;
  }

  h6 {
    font-family: unset;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
  }

  @media (max-width: 959px) {
    padding: 24px;

    h2 {
      font-size: 30px;
      line-height: unset;
      margin-bottom: 12px;
    }
  }
`;

const StyledWrapperInner = styled(Box)`
  overflow-y: scroll;
  padding: 24px 24px 24px 0;
  // margin-bottom: 82px;

  @media (max-width: 959px) {
    margin-bottom: 24px;
  }
`;

const StyledGroup = styled(Box)`
  margin-bottom: 2rem;

  p {
    margin: 0;
    margin-bottom: 0.25rem;
  }
`;

const Impressum = () => {
  return (
    <Fragment>
      <StyledContainer fixed>
        <Grid container style={{ marginBottom: "88px" }}>
          <Grid item xs={12}>
            <StyledWrapperOuter>
              <h2>Impressum</h2>
              <StyledWrapperInner>
                <h6>Home United Spaces GmbH</h6>
                <StyledGroup>
                  <p>Hamburger Ding</p>
                  <p>Nobistor 16</p>
                  <p>22767 Hamburg</p>
                </StyledGroup>
                <StyledGroup>
                  <p>+49 40. 419 297 000</p>
                  <p>gaming@unitedcyberspaces.com</p>
                  <p>www.unitedcyberspaces.com</p>
                </StyledGroup>
                <StyledGroup>
                  <p>Geschäftsführer</p>
                  <p>Dipl.-Ing. Tomislav Karajica</p>
                </StyledGroup>
                <StyledGroup>
                  <p>Amtsgericht Hamburg: HRB 163632</p>
                  <p>Steuernummer: 46/734/05598</p>
                </StyledGroup>
                <StyledGroup>
                  <p>Aufsichtsbehörde gemäß §6 Teledienstegesetz:</p>
                  <p>Freie und Hansestadt Hamburg</p>
                  <p>Bezirksamt Hamburg-Nord, Fachamt Verbraucherschutz,</p>
                  <p>Gewerbe und Umwelt</p>
                  <p>Kümmellstraße 7</p>
                  <p>20243 Hamburg</p>
                  <p>E-Mail: Verbraucherschutz@hamburg-nord.hamburg.de</p>
                </StyledGroup>
                <StyledGroup>
                  <p>Haftungshinweis</p>
                  <p>
                    Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir
                    keine Haftung für die Inhalte externer Links. Für den Inhalt
                    der verlinkten Seiten sind ausschließlich deren Betreiber
                    verantwortlich.
                  </p>
                </StyledGroup>
              </StyledWrapperInner>
            </StyledWrapperOuter>
          </Grid>
        </Grid>
      </StyledContainer>
      <GdprFooter />
    </Fragment>
  );
};

export default Impressum;
