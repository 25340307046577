import React, { useEffect } from "react";
import { Authenticator, Greetings } from "aws-amplify-react";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import { I18n } from "aws-amplify";
import { view } from "@risingstack/react-easy-state";

import { auth } from "../stores/BaseStore";

import AuthStateHandler from "./AuthStateHandler";

const signUpConfig = {
  hideAllDefaults: true,
  signUpFields: [
    {
      label: "Email",
      placeholder: "Email",
      key: "username",
      required: true,
      displayOrder: 1,
      type: "string",
      autocomplete: "off",
    },
    {
      label:
        "Password (At least 8 characters, 1 uppercase letter and 1 number)",
      placeholder: "Password",
      key: "password",
      required: true,
      displayOrder: 2,
      type: "password",
    },
    {
      label: "Full name",
      placeholder: "Full name",
      key: "name",
      required: true,
      displayOrder: 3,
      type: "string",
    },
    {
      label: "Street address",
      placeholder: "Street address",
      key: "custom:street",
      required: true,
      displayOrder: 4,
      type: "string",
    },
    {
      label: "ZIP code",
      placeholder: "ZIP code",
      key: "custom:zip",
      required: true,
      displayOrder: 5,
      type: "string",
    },
    {
      label: "City",
      placeholder: "City",
      key: "custom:city",
      required: true,
      displayOrder: 6,
      type: "string",
    },
    {
      label: "Country",
      placeholder: "Country",
      key: "custom:country",
      required: true,
      displayOrder: 7,
      type: "string",
    },
  ],
};

const signInConfig = {
  header: "Sign in",
};

const StyledAuthWrapper = styled(Box)`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #161e20;
  z-index: 2;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s;
    -webkit-text-fill-color: #a7acb3 !important;
  }

  input:-webkit-autofill::first-line {
    font-size: 21px;
  }

  div {
    &[class^="Toast__toast"] {
      // color: #000000;
      background-color: #0091d4;
      [class^="Toast__toastClose"] {
        &::before {
          background-color: #fffffff;
        }
        &::after {
          background-color: #fffffff;
        }
      }
    }

    &[class^="Form__formSection"] {
      background-color: transparent;
      border-radius: 0;
      box-shadow: none;
      max-width: 525px;
      padding-top: 130px;

      // @media (max-width: 959px) {
      //   padding-top: 230px;
      // }

      [class^="Section__sectionHeaderContent"] {
        color: #ffffff;

        span {
          color: #0091d4;
        }
      }

      [class^="Section__sectionHeader"] {
        margin-bottom: 55px;
      }

      [class^="Section__sectionBody"] {
        margin-bottom: 80px;
      }

      [class^="Input__input"] {
        color: #a7acb3;
        background-color: transparent;
        font-size: 21px;
        border-radius: 0;
        border: none;
        border-bottom: 1px solid rgba(167, 172, 180, 0.5);
        outline: none !important;
        box-shadow: none !important;
        margin-bottom: 40px;
        padding: 12px 16px 20px 16px;

        &::placeholder {
          color: #a7acb3;
        }
        &::-webkit-input-placeholder {
          color: #a7acb3;
        }
        &::-moz-placeholder {
          color: #a7acb3;
        }
        &:-ms-input-placeholder {
          color: #a7acb3;
        }
        &:-moz-placeholder {
          color: #a7acb3;
        }
      }

      [class^="Input__inputLabel"] {
        font-family: "Polaris", "Arial", sans-serif;
        font-size: 14px;
        color: #ffffff;
        border-radius: 0;
        border: none;
        border-bottom: none;
        margin-bottom: 8px;
        padding: 0 0 0 16px;
      }

      [class^="Hint__hint"] {
        color: #ffffff;
        font-size: 14px;
        padding: 0 0 0 16px;
      }

      [class^="Anchor__a"] {
        color: #0091d4;
        text-decoration: none;
      }

      [class^="Section__sectionFooter"] {
        flex-wrap: wrap;
      }

      [class^="Section__sectionFooterPrimaryContent"] {
        width: 100%;
        color: #ffffff;
        background-color: transparent;
        margin-bottom: 36px;

        [class^="Button__button"] {
          width: 100%;
          color: #ffffff;
          font-size: 16px;
          background-color: transparent;
          border: 1px solid #fafafa;
          padding: 20px;
        }
      }

      [class^="Section__sectionFooterSecondaryContent"] {
        color: #ffffff;
        font-size: 21px;
      }
    }
  }
`;

const signInMessage = (
  <h2>
    <span>Sign in</span> to your account
  </h2>
);

const signUpMessage = (
  <h2>
    Create a <span>new account</span>
  </h2>
);

const confirmSignUpMessage = (
  <h2>
    <span>Confirm</span> sign up
  </h2>
);

const forgotPasswordMessage = (
  <h2>
    <span>Reset</span> password
  </h2>
);

const authScreenLabels = {
  en: {
    "Sign in to your account": signInMessage,
    "Enter your username": "Enter your email",
    "Create a new account": signUpMessage,
    "Confirm Sign Up": confirmSignUpMessage,
    "Reset your password": forgotPasswordMessage,
    "Confirmation Code": "Confirmation Code (sent to e-mail)",
  },
};

I18n.setLanguage("en");
I18n.putVocabularies(authScreenLabels);

const Auth = view(() => {
  const removeAutoComplete = () => {
    const allInputs = document.querySelectorAll("input");

    allInputs.forEach((input) => {
      input.setAttribute("autoComplete", "off");
    });
  };

  useEffect(() => {
    removeAutoComplete();
    // eslint-disable-next-line
  }, [auth.authState]);

  return (
    <StyledAuthWrapper>
      <Authenticator
        signInConfig={signInConfig}
        signUpConfig={signUpConfig}
        usernameAttributes="Email"
        hide={[Greetings]}
      >
        <AuthStateHandler />
      </Authenticator>
    </StyledAuthWrapper>
  );
});

export default Auth;
