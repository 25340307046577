import React from "react";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
  all: unset;
  cursor: pointer;
`;

const StyledOuterWrapper = styled(Box)`
  background-color: #161e20;
  padding: 24px;
  margin-bottom: 40px;
  display: flex;
  position: relative;
`;

const StyledNumber = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  height: 52px;
  width: 52px;
  background-color: #0091d4;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Polaris", "Arial", sans-serif;
  font-size: 21px;
  line-height: 16px;
  letter-spacing: 1.875px;
  text-transform: uppercase;
`;

const StyledImageWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  margin-right: 24px;
  margin-bottom: 16px;
`;

const StyledAvatar = styled.img`
  margin-bottom: 16px;
  height: 88px;
  width: 88px;
  object-fit: cover;
`;

const StyledInfoWrapper = styled(Box)`
  padding-top: 8px;
`;

const StyledSkill = styled.p`
  font-family: "AzoSans Bold", "Arial", sans-serif;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.0857143px;
  text-transform: uppercase;
  color: #878c92;
  margin: 0;
`;

const StyledName = styled.p`
  font-family: "AzoSans Black", "Arial", sans-serif;
  font-size: 21px;
  line-height: 25px;
  letter-spacing: 0.154412px;
  margin: 0;
  margin-bottom: 6px;
`;

const StyledRank = styled.p`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.114286px;
  color: #0091d4;
  margin: 0;
  margin-bottom: 24px;
  text-transform: uppercase;

  span {
    font-family: "AzoSans Black", "Arial", sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    text-transform: lowercase;
  }
`;

const StyledMode = styled.p`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.114286px;
  color: #878c92;
  margin: 0;
  margin-bottom: 4px;
`;

const StyledRatio = styled.p`
  font-family: "AzoSans Black", "Arial", sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.114286px;
  color: #0091d4;
  margin: 0;
  margin-bottom: 24px;
  margin-top: 12px;
`;

const PlayerBoxDota = (props) => {
  const { singlePlayer } = props;

  return (
    <StyledLink to={`/players/dota/${singlePlayer.id}`}>
      <StyledOuterWrapper>
        <StyledNumber>{singlePlayer.id}</StyledNumber>
        <StyledImageWrapper>
          <StyledAvatar src={singlePlayer.avatar_link} alt="avatar" />
        </StyledImageWrapper>
        <StyledInfoWrapper>
          <StyledSkill>Veteran</StyledSkill>
          <StyledName>{singlePlayer.dota2_name}</StyledName>
          <StyledMode>{singlePlayer.dota2_account_id}</StyledMode>
          <StyledRank>
            {singlePlayer.preferred_position}{" "}
            <span>
              {" "}
              {singlePlayer.rank_tier
                ? `rank ${singlePlayer.rank_tier}`
                : "NO DATA"}
            </span>
          </StyledRank>
          <StyledRatio>
            {singlePlayer.wins + singlePlayer.loses}G {singlePlayer.wins}W{" "}
            {singlePlayer.loses}L
          </StyledRatio>
          <StyledRank>
            KDA <span>{singlePlayer.kda}</span>
          </StyledRank>
        </StyledInfoWrapper>
      </StyledOuterWrapper>
    </StyledLink>
  );
};

export default PlayerBoxDota;
