import React, { Fragment, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import { view } from "@risingstack/react-easy-state";

import { trainings } from "../../stores/BaseStore";
import TrainingFeaturedBox from "../trainings/TrainingFeaturedBox";
import TrainingBox from "../trainings/TrainingBox";
import HeroNews from "../shared/HeroNews";
import Loader from "../shared/Loader";
import Footer from "../shared/Footer";

const StyledContainer = styled(Container)`
  position: relative;
  z-index: 2;
  padding-top: 230px;
`;

const StyledTitleWrapper = styled(Box)`
  h4 {
    text-transform: unset;
  }
`;

// const StyledButtonSecondary = styled.button`
//   width: 100%;
//   color: #ffffff;
//   font-size: 16px;
//   background-color: transparent;
//   border: 1px solid #fafafa;
//   border-radius: 0;
//   padding: 20px;
//   cursor: pointer;
//   letter-spacing: 1.42857px;

//   &:hover {
//     opacity: 0.8;
//   }

//   width: 200px;

//   &.active {
//     background-color: #0091d4;
//     border: 1px solid #0091d4;
//   }
// `;

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const Trainings = view(() => {
  // const [mode, setMode] = useState("list");

  useEffect(() => {
    if (!trainings.trainings) {
      trainings.getTrainings();
    }
  }, []);

  return (
    <Fragment>
      <StyledContainer fixed>
        {!trainings.trainings ? (
          <Fragment>
            <StyledSpacer />
            <Loader />
          </Fragment>
        ) : (
          <Fragment>
            <Grid
              container
              justify={"space-between"}
              alignItems={"center"}
              style={{ marginBottom: "40px" }}
            >
              <StyledTitleWrapper>
                <h4 style={{ margin: 0 }}>Upcoming trainings</h4>
              </StyledTitleWrapper>
              {/* <Box>
                <StyledButtonSecondary
                  style={{ marginRight: "36px" }}
                  onClick={() => {
                    setMode("list");
                  }}
                  className={mode === "list" ? "active" : null}
                >
                  list
                </StyledButtonSecondary>
                <StyledButtonSecondary
                  onClick={() => {
                    setMode("calendar");
                  }}
                  className={mode === "calendar" ? "active" : null}
                >
                  calendar
                </StyledButtonSecondary>
              </Box> */}
            </Grid>
            <Grid container spacing={9} style={{ marginBottom: "88px" }}>
              {trainings.trainings.map((singleTraining, index) => {
                if (index === 0) {
                  return (
                    <Grid
                      item
                      xs={12}
                      style={{ marginBottom: "28px" }}
                      key={singleTraining.id}
                    >
                      <TrainingFeaturedBox singleTraining={singleTraining} />
                    </Grid>
                  );
                } else {
                  return (
                    <Grid item xs={12} md={6} key={singleTraining.id}>
                      <TrainingBox singleTraining={singleTraining} />
                    </Grid>
                  );
                }
              })}
            </Grid>
            <HeroNews />
          </Fragment>
        )}
      </StyledContainer>
      <Footer />
    </Fragment>
  );
});

export default Trainings;
